import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

// wall calendar with small dots
const Calendar = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.16602C2 5.33759 2.67157 4.66602 3.5 4.66602H20.5C21.3284 4.66602 22 5.33759 22 6.16602V20.4993C22 21.3278 21.3284 21.9993 20.5 21.9993H3.5C2.67157 21.9993 2 21.3278 2 20.4993V6.16602Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 10H22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.3335 6.66667V2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6665 6.66667V2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33333 13.334C6.14924 13.334 6 13.4832 6 13.6673C6 13.8514 6.14924 14.0007 6.33333 14.0007C6.51743 14.0007 6.66667 13.8514 6.66667 13.6673C6.66667 13.4832 6.51743 13.334 6.33333 13.334"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33333 18C6.14924 18 6 18.1492 6 18.3333C6 18.5174 6.14924 18.6667 6.33333 18.6667C6.51743 18.6667 6.66667 18.5174 6.66667 18.3333C6.66667 18.1492 6.51743 18 6.33333 18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9998 13.334C11.8157 13.334 11.6665 13.4832 11.6665 13.6673C11.6665 13.8514 11.8157 14.0007 11.9998 14.0007C12.1839 14.0007 12.3332 13.8514 12.3332 13.6673C12.3332 13.4832 12.1839 13.334 11.9998 13.334"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9998 18C11.8157 18 11.6665 18.1492 11.6665 18.3333C11.6665 18.5174 11.8157 18.6667 11.9998 18.6667C12.1839 18.6667 12.3332 18.5174 12.3332 18.3333C12.3332 18.1492 12.1839 18 11.9998 18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6668 13.334C17.4827 13.334 17.3335 13.4832 17.3335 13.6673C17.3335 13.8514 17.4827 14.0007 17.6668 14.0007C17.8509 14.0007 18.0002 13.8514 18.0002 13.6673C18.0002 13.4832 17.8509 13.334 17.6668 13.334"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6668 18C17.4827 18 17.3335 18.1492 17.3335 18.3333C17.3335 18.5174 17.4827 18.6667 17.6668 18.6667C17.8509 18.6667 18.0002 18.5174 18.0002 18.3333C18.0002 18.1492 17.8509 18 17.6668 18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Calendar;
