import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cellsSlices } from "../cells/slice";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import type { MetadataState } from "../metadata/slice";
import type { ModuleState } from "./slice";
import { takeOff, readyOff, discontinueCells } from "./slice";

import { resetRemoveFromModule, removeFromModule } from "./slice";

import ButtonAccessSignalMap from "../signal-maps/ButtonAccessSignalMap";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import ReadyOffMenu from "../cells/ReadyOffMenu";
import TestModal from "../metadata/layout/TestModal";
import Toast from "../../components/Toast";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const ModCellOptions = ({
  exp_id,
  module_id,
  has_signal_map,
  isBAM,
  bamSN,
  cells,
  selected,
}: {
  exp_id: string;
  module_id: string;
  has_signal_map: boolean;
  isBAM: boolean;
  bamSN?: string | null;
  cells: Cell[];
  selected: number[];
}) => {
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);
  const {
    status: { onTest: status },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  const selectedCells = cells.filter((cell) => selected.includes(cell.cell_id));
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [testModalOpen, setTestModalOpen] = useState(false);
  const testModal = testModalOpen ? (
    <TestModal
      open={testModalOpen}
      onClose={() =>
        status === "succeeded"
          ? window.location.reload()
          : setTestModalOpen(false)
      }
      module_id={`${module_id}`}
      reserved_channel={""} // Channels cannot be reserved for modules
      executor={user}
      conditionIdsToCellIdsForMetadataFetch={selectedCells.reduce(
        (
          conditionIdsToRequestedCellIds: { [key: string]: string[] },
          selectedCell
        ) => {
          const cellConditionId = selectedCell.condition.cell_condition_id;
          if (!cellConditionId) {
            return conditionIdsToRequestedCellIds;
          }
          if (
            !Object.keys(conditionIdsToRequestedCellIds).includes(
              String(cellConditionId)
            )
          ) {
            conditionIdsToRequestedCellIds[cellConditionId] = [];
          }
          conditionIdsToRequestedCellIds[cellConditionId] = [
            ...conditionIdsToRequestedCellIds[cellConditionId],
            String(selectedCell.cell_id),
          ];
          return conditionIdsToRequestedCellIds;
        },
        {}
      )}
      selectedCells={selectedCells}
    />
  ) : null;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    status: { remove: removeStatus },
    error: { remove: removeError },
  } = useSelector<RootState, ModuleState>(({ modules }) => modules);

  useEffect(() => {
    if (removeStatus === "succeeded") {
      dispatch(resetRemoveFromModule());
      dispatch(cellsSlices.EXPERIMENT.resetCellListState());
      setSuccess(true);
    } else if (removeStatus === "failed") {
      setError(removeError);
    }
  }, [removeStatus, dispatch, removeError]);

  return (
    <>
      {selected.length > 0 && (
        <ButtonLogEvent itemType={"cell"} objects={selectedCells} />
      )}

      <Button
        size="small"
        color="tertiary"
        type="button"
        onClick={() => {
          const endpoint = isBAM
            ? `/metadata/module/${module_id}`
            : `/metadata/batch-external-data-entry/${cells
                .map((cell_) => cell_.cell_id)
                .join(",")}`;
          navigate(endpoint);
        }}
      >
        <b>Input module metadata</b>
      </Button>

      <ButtonAccessSignalMap
        dut_id={`${module_id}`}
        dutType="module"
        has_signal_map={has_signal_map}
      />

      <ReadyOffMenu
        showRemoveFromModule={true}
        exp_id={`${exp_id}`}
        disablePutOn={
          // Disable on-test if not all cells are "Fill Complete" or "Off Test"...
          cells.some(
            (cell) => !["L", "F"].includes(cell.status) || cell.backfill
          ) ||
          // ...or if not all the module's cells are selected...
          cells
            .map((cell) => cell.cell_id)
            .sort()
            .join(",") !== selected.sort().join(",") ||
          // ...or if the module is NOT a BAM and doesn't have a signal map
          (!isBAM && !has_signal_map) ||
          // ...or if it is a BAM that doesn't have a serial #
          (isBAM && !bamSN)
        }
        onPutOn={() => setTestModalOpen(true)}
        showTakeOff={selectedCells.filter((cell) => cell.backfill).length > 0}
        disableTakeOff={
          (selectedCells.filter((cell) => cell.backfill).length > 0 &&
            selectedCells.filter((cell) => !cell.backfill).length > 0) ||
          selectedCells.filter((cell) => !["P", "N"].includes(cell.status))
            .length > 0
        }
        onTakeOff={() =>
          dispatch(
            takeOff({
              cells: selectedCells,
              user_id: user?.user_id,
            })
          )
        }
        selected={selectedCells.sort((a, b) =>
          a.cell_id > b.cell_id ? 1 : -1
        )}
        onReadyOff={() =>
          dispatch(
            readyOff({
              cells: selectedCells,
              cancel: false,
              user_id: user?.user_id,
            })
          )
        }
        onCancelReadyOff={() =>
          dispatch(
            readyOff({
              cells: selectedCells,
              cancel: true,
              user_id: user?.user_id,
            })
          )
        }
        onDiscontinue={() =>
          dispatch(
            discontinueCells({
              cells: selectedCells,
              cancel: false,
            })
          )
        }
        onCancelDiscontinue={() =>
          dispatch(
            discontinueCells({
              cells: selectedCells,
              cancel: true,
            })
          )
        }
        onRemoveFromModule={() =>
          dispatch(
            removeFromModule({
              cells: selectedCells,
            })
          )
        }
      />
      {testModal}

      {success && (
        <Toast open severity="success" onClose={() => setSuccess(false)}>
          Cell{selected.length > 1 && "s"} successfully removed from module
        </Toast>
      )}

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default ModCellOptions;
