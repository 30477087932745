import React, {
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { CellListState, cellsSlices } from "./slice";
import Modal from "../../components/Modal";
import colors from "../../theme/colors";
import Button from "../../components/Button";
import ChannelDropdown from "../channels/ChannelDropdown";
import ChannelTableCell from "../channels/ChannelTableCell";
import { CHANNEL_LABELS_ALL } from "../../utils/labels";
import ConditionCell from "../../components/forms/ConditionCell";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Banner from "../../components/Banner";

export const useChannelCellStyles = makeStyles({
  root: {
    "& .MuiTableCell-root": {
      "& .thCellInner": {},
      "& .MuiOutlinedInput-root.MuiInputBase-root": {
        border: 0,
      },
      "&.MuiTableCell-head": {
        background: "white",
      },
    },
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  stateKey: keyof typeof cellsSlices;
  selected: Cell[];
};

const ReserveChannelsModal = ({ open, onClose, stateKey, selected }: Props) => {
  const classes = useChannelCellStyles();
  const dispatch = useDispatch();

  const [channelSelection, setChannelSelection] = useState<
    Record<number, Channel | null>
  >({});

  const {
    status: { channelReservation: channelResStatus, list: listCellsStatus },
    error: { channelReservation: error },
  } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );

  useEffect(() => {
    if (
      channelResStatus === "idle" &&
      ["idle", "succeeded"].includes(listCellsStatus)
    ) {
      const selections: Record<number, Channel | null> = {};
      let unique_condition_ids = new Set();
      selected.forEach(({ cell_id, reserved_channel, condition }) => {
        selections[cell_id] = reserved_channel;
        unique_condition_ids.add(condition.cell_condition_id);
      });
      setChannelSelection(selections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (open && channelResStatus === "succeeded") {
      onClose();
    }
  }, [open, channelResStatus, onClose]);

  const cellIds = selected.map(({ cell_id }) => cell_id);
  let modules: Module[] = [];
  selected.forEach((cell) => {
    if (!!cell.module.module_id) {
      let mod = cell.module as Module;
      let cell_module: any = modules.find((m) => m.module_id === mod.module_id);
      if (cell_module === undefined) {
        cell_module = { ...mod, cells: [] };
        modules.push(cell_module);
      }

      cell_module["cells"].push(cell);
    }
  });

  return (
    <Modal open={open} onClose={onClose} maxWidth={false}>
      <Typography variant="h2">Reserve channels</Typography>
      <Box mt={2} mb={6}>
        <Typography className="small" color="textSecondary">
          Use this to complete <a href="https://wiki.formenergy.com/display/BTF/WI%3A+Reserving+Channels+in+the+Subscale+Lab" target="_blank" rel="noreferrer">WI: Reserving Channels in Subscale Lab</a>.
        </Typography>
      </Box>

      <TableContainer>
        <Table
          className={`dataTable ${classes.root}`}
          size="small"
          style={{ background: colors.striping, width: "auto" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ background: "white" }} />
              {cellIds.map((cell_id, index) => (
                <TableCell key={index} style={{ background: "white" }}>
                  Cell ID {`${cell_id}`.padStart(6, "0")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Reserved Channel ID</TableCell>
              {cellIds.map((cell_id, index) => {
                const cell_ = selected.find(
                  (selectedCell) => selectedCell.cell_id === cell_id
                )!;
                const allowQueueing =
                  !!cell_.cell_type.allow_reservation_queueing;
                return (
                  <ConditionCell
                    key={index}
                    style={{ background: "white" }}
                    padding="none"
                  >
                    <ChannelDropdown
                      allowQueueing={allowQueueing}
                      hideTestStandChannels
                      value={channelSelection[cell_id] || null}
                      onChange={(channel) => {
                        // Update channel selection for this cell and
                        // all sibling cells if it's in a module
                        let updatedChannelSelection = {
                          ...channelSelection,
                          [cell_id]: channel,
                        };

                        modules.forEach((mod) => {
                          let cellIds = mod.cells.flatMap(
                            (cell) => cell.cell_id
                          );
                          let index = cellIds.indexOf(cell_id);

                          if (index > -1) {
                            cellIds.splice(cellIds.indexOf(cell_id), 1);
                            cellIds.forEach(
                              (cell_id) =>
                                (updatedChannelSelection[cell_id] = channel)
                            );
                          }
                        });

                        setChannelSelection(updatedChannelSelection);
                      }}
                      cells={cellIds}
                    />
                  </ConditionCell>
                );
              })}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(({ id }) => id === "channel__pool")
                    ?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__pool"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__preferred_test_vehicle"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__preferred_test_vehicle"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__temperature"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__temperature"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__airlines"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__airlines"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__aux_channels"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__aux_channels"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__aux_voltage_box_type"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__aux_voltage_box_type"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__flow_valve_type"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__flow_valve_type"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__hazelnut_installed"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__hazelnut_installed"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) =>
                      id === "channel__subscale_h2_interlock_installed"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__subscale_h2_interlock_installed"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__tc_channels"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__tc_channels"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__cathode_switching"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__cathode_switching"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__co2_scrubbing"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__co2_scrubbing"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__humidity_control"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__humidity_control"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__tester_brand"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__tester_brand"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__low_current_range"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__low_current_range"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__high_current_range"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__high_current_range"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__min_voltage"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__min_voltage"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__max_voltage"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__max_voltage"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__hydrogen_monitoring"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__hydrogen_monitoring"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__pressure_setpoint"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__pressure_setpoint"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__aux_voltage_monitoring"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__aux_voltage_monitoring"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__eis_functionality"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__eis_functionality"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) =>
                      id === "channel__thermal_uniformity_fans_installed"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__thermal_uniformity_fans_installed"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__kilobubbler_count"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__kilobubbler_count"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__yearly_calibration_date"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__yearly_calibration_date"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {channelResStatus === "failed" ? (
        <Box
          position="fixed"
          style={{ top: 74, width: "80%" }}
          m={4}
          zIndex={10}
        >
          <Banner severity="error">
            {error || "Error saving channel reservations."}
          </Banner>
        </Box>
      ) : null}

      <Box mt={6} display="flex">
        <Button
          color="tertiary"
          size="small"
          onClick={() => {
            setChannelSelection({});
          }}
        >
          <b>Reset</b>
        </Button>
        <Box ml="auto" mr={2}>
          <Button color="secondary" size="small" onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Button
          color="cta"
          size="small"
          disabled={
            channelResStatus === "loading" ||
            Object.keys(channelSelection).filter(
              (key) =>
                !(
                  channelSelection[parseInt(key)] === null ||
                  channelSelection[parseInt(key)]?.channel.fullname === null
                )
            ).length !== selected.length
          }
          startIcon={
            channelResStatus === "loading" ? (
              <CircularProgress color="inherit" size={20} />
            ) : null
          }
          onClick={() =>
            dispatch(cellsSlices[stateKey].reserveChannels(channelSelection))
          }
        >
          Reserve channels
        </Button>
      </Box>
    </Modal>
  );
};

export default ReserveChannelsModal;
