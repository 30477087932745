import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import type { FieldPath, PathValue, UnpackNestedValue } from "react-hook-form";
import isNull from "lodash/isNull";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import ButtonCell from "./ButtonCell";
import ConditionCell from "./ConditionCell";
import CheckboxCell from "./CheckboxCell";
import InputCell from "./InputCell";
import MetaCell from "./MetaCell";
import ReferenceCalibrationCell from "../../features/metadata/layout/ReferenceCalibrationCell";
import SelectCell from "./SelectCell";
import TextareaCell from "./TextareaCell";
import UserCell from "./UserCell";
import TeardownSpecificationCell from "./TeardownSpecificationCell";
import PhotoCell from "./PhotoCell";
import UserSearch from "../UserSearch";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MuiDateTimePicker from "@mui/lab/DateTimePicker";
import TeardownFlagUpdateCell from "./TeardownUpdateFlagSpecCell";
import {
  dependantFieldsToManage,
  safetyManager,
} from "../../utils/safetyRules";
import { getConditionIndexFromKey } from "../../utils/conditions";
import ConditionDatePicker from "../../features/conditions/ConditionDatePicker";

type Props = {
  noTable?: boolean;
  name: string;
  uiField: ConditionUIField;
  className?: string;
  onBlur: () => void;
  onChange: (...event: any[]) => void;
  value: any;
  pendingChange?: any;
  error: boolean;
  disabled?: boolean;
  conditionKey: string;
  endAdornment?: React.ReactNode;
};

const TdCell = forwardRef(
  <T extends ConditionRHFormFormat | MetadataRHFormFormat>(
    {
      noTable = false,
      name,
      uiField,
      className: _className,
      onBlur,
      onChange,
      value,
      pendingChange,
      error,
      disabled: _disabled,
      conditionKey,
      endAdornment,
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { setValue, control, getValues } = useFormContext<T>();

    let stateKey = "";
    if (uiField.enabled_by) {
      stateKey = conditionKey
        ? `${conditionKey}.${uiField.enabled_by.id}`
        : uiField.enabled_by.id;
    }

    const state = useWatch({
      control,
      name: stateKey as FieldPath<T>,
      disabled: !stateKey,
    });

    // Auth state in redux
    const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

    // Field-specific state
    const disabled =
      uiField.readonly ||
      _disabled ||
      (uiField.enabled_by && (uiField.enabled_by.neg ? state : !state));

    let _onChange = onChange;
    if (uiField.id.includes("engineer_flag")) {
      _onChange = (value) => {
        const executorKey = uiField.id.replace(
          "engineer_flag",
          "preferred_executor"
        );

        const fullExecutorKey = (
          conditionKey ? `${conditionKey}.${executorKey}` : executorKey
        ) as FieldPath<T>;

        if (value) {
          setValue(fullExecutorKey, user as any, {
            shouldDirty: true,
          });
        } else {
          setValue(fullExecutorKey, null as any, {
            shouldDirty: true,
          });
        }
        onChange(value);
      };
    }

    // Custom onChange function for repeated fields
    if (uiField.type.includes("*")) {
      _onChange = (new_value, i) => {
        value[i] = new_value;
        onChange(value);
      };
    }

    let className = _className || "";
    if (uiField.readonly) {
      className += " readonlyField";
    }
    let dependencyPath: FieldPath<T> | null = null;

    if (dependantFieldsToManage.includes(uiField.id)) {
      const conditionIndex = getConditionIndexFromKey(
        conditionKey as FieldPath<ConditionRHFormFormat>
      );
      const { dependencyField } =
        safetyManager[uiField.id as "on_test__hydrogen_monitoring"];
      dependencyPath =
        `conditions.${conditionIndex}.${dependencyField}` as FieldPath<T>;
    }

    const dependencyState = useWatch({
      control,
      name: dependencyPath as FieldPath<T>,
      disabled: !dependencyPath,
    });

    switch (uiField.type) {
      case "bool":
      case "boolean":
        let disableDependantCheckboxDueToDependencyValue = false;
        if (dependencyPath && dependencyState) {
          const { dependencyFieldConditions } =
            safetyManager[uiField.id as "on_test__hydrogen_monitoring"];
          if (
            dependencyFieldConditions.includes(dependencyState.toLowerCase())
          ) {
            disableDependantCheckboxDueToDependencyValue = true;
            const dependantField =
              `${conditionKey}.${uiField.id}` as FieldPath<T>;
            if (!getValues(dependantField)) {
              setValue(
                dependantField,
                true as UnpackNestedValue<PathValue<T, FieldPath<T>>>,
                { shouldDirty: true }
              );
            }
          }
        }
        return (
          <CheckboxCell
            ref={ref}
            noTable={noTable}
            color="secondary"
            name={name}
            className={className}
            disabled={disableDependantCheckboxDueToDependencyValue || disabled}
            checked={Boolean(value)}
            onBlur={onBlur}
            onChange={(e) => _onChange(e.target.checked)}
            pendingChange={pendingChange}
          />
        );
      case "number":
        return (
          <InputCell
            ref={ref}
            noTable={noTable}
            name={name}
            className={className}
            disabled={disabled}
            error={error}
            type="number"
            title={isNull(value) ? "" : value}
            value={isNull(value) ? "" : value}
            onBlur={onBlur}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              _onChange(isNaN(value) ? null : e.target.value);
            }}
            inputProps={{
              className: `qa-${uiField.id}-input`,
              onWheel: (e) => e.currentTarget.blur(),
            }}
            pendingChange={pendingChange}
            endAdornment={endAdornment}
          />
        );
      // TODO: Figure out a way to make this dynamic based on # of reps
      case "number*3":
        return (
          <div style={{ display: "flex" }}>
            {[...Array(3)].map((x, i) => (
              <InputCell
                ref={ref}
                key={i}
                noTable={noTable}
                name={`${name}__rep_${i + 1}`}
                className={className}
                disabled={disabled}
                error={error && value[i] === null}
                style={{ padding: "0px 5px" }}
                type="number"
                title={isNull(value) ? "" : value[i]}
                value={isNull(value) ? "" : value[i]}
                onBlur={onBlur}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  _onChange(isNaN(value) ? null : e.target.value, i);
                }}
                inputProps={{
                  className: `qa-${uiField.id}__rep_${i + 1}-input`,
                  onWheel: (e) => e.currentTarget.blur(),
                }}
                pendingChange={pendingChange}
                endAdornment={endAdornment}
              />
            ))}
          </div>
        );
      case "date":
        return (
          <ConditionDatePicker name={name} value={value} onChange={_onChange} />
        );
      case "datetime":
        return (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <MuiDateTimePicker
              renderInput={(props) => <TextField {...props} error={error} />}
              value={value}
              onChange={(val) => val && onChange(new Date(val))}
            />
          </LocalizationProvider>
        );
      case "text":
        return (
          <TextareaCell
            ref={ref}
            noTable={noTable}
            name={name}
            className={className}
            disabled={disabled}
            error={error}
            title={isNull(value) ? "" : value}
            value={isNull(value) ? "" : value}
            onBlur={onBlur}
            onChange={_onChange}
            pendingChange={pendingChange}
            endAdornment={endAdornment}
          />
        );
      case "string":
      case "icmpcmlot_options": // -- PRESENT --
        return (
          <InputCell
            ref={ref}
            noTable={noTable}
            name={name}
            className={className}
            disabled={disabled}
            error={error}
            type="text"
            title={isNull(value) ? "" : value}
            value={isNull(value) ? "" : value}
            onBlur={onBlur}
            onChange={_onChange}
            pendingChange={pendingChange}
            endAdornment={endAdornment}
          />
        );
      case "button":
        const hrefVal = Array.isArray(uiField.default)
          ? uiField.default[0]
          : uiField.default;
        return (
          <ButtonCell
            className={className}
            label={uiField.label.includes("SOP") ? "SOP" : "Link"}
            href={hrefVal}
          />
        );
      case "link":
        return (
          <ButtonCell
            className={className}
            label={uiField.label}
            href={value}
          />
        );
      case "user_options":
        return (
          <UserCell
            ref={ref}
            noTable={noTable}
            className={className}
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            clearable={true}
            pendingChange={pendingChange}
          />
        );
      case "multiple_user_options":
        return (
          <UserSearch
            onChange={onChange}
            onBlur={onBlur}
            value={value === null ? undefined : value}
            invalid={error}
            multiple={true}
          />
        );
      case "icmpcm_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}
            className={className}
            endpoint="meta/materials"
            valueKey="material_id"
            descriptionKey="description"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            pendingChange={pendingChange}
          />
        );
      /*
      -- FUTURE --
      case "icmpcmlot_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}



            className={className}
            endpoint="meta/material-lots"
            valueKey="material_lot_id"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            pendingChange={pendingChange}
          />
        );
      */
      case "cyclingprotocol_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}
            className={className}
            endpoint="meta/cycling-protocols/spec"
            valueKey="cycling_protocol_id"
            descriptionKey="description"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            pendingChange={pendingChange}
          />
        );
      case "sopdeviation_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}
            className={className}
            multiple
            freeSolo
            endpoint="meta/deviation-types"
            valueKey="deviation_type_name"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value || []}
            pendingChange={pendingChange}
          />
        );
      case "channel_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}
            className={className}
            endpoint="meta/channels"
            valueKey="channel_id"
            searchKey="channel_fullname"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            pendingChange={pendingChange}
          />
        );
      case "channel_pool_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}
            className={className}
            endpoint="meta/channels/pool-ids"
            searchKey="pool"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            pendingChange={pendingChange}
          />
        );
      case "options":
      case "number_options":
      case "multiple_options":
        return (
          <SelectCell
            multiple={uiField.type === "multiple_options"}
            disableClearable={uiField.required}
            ref={ref}
            noTable={noTable}
            className={className}
            endAdornment={endAdornment}
            options={uiField.options}
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={
              value
                ? uiField.type === "number_options"
                  ? String(value)
                  : value
                : uiField.type === "multiple_options"
                ? []
                : null
            }
            pendingChange={pendingChange}
          />
        );
      case "reference_calibration":
        return <ReferenceCalibrationCell className={className} value={value} />;
      case "update_teardown_flags":
        return (
          <TeardownFlagUpdateCell className={className} uiField={uiField} />
        );
      case "component_meta_mode":
        return (
          <TeardownSpecificationCell
            className={className}
            disabled={disabled}
            label={uiField.label}
            options={uiField.options}
            onChange={_onChange}
            value={value}
          />
        );
      case "photo":
        return (
          <PhotoCell
            noTable={noTable}
            className={className}
            disabled={disabled}
            error={error}
            onChange={_onChange}
            value={value}
          />
        );
      case "oee_id_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}
            className={className}
            endpoint="meta/cell-components/oee-ids/available"
            searchKey="oee_id"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            pendingChange={pendingChange}
            freeSolo={true}
          />
        );
      case "gde_id_options":
        return (
          <MetaCell
            ref={ref}
            noTable={noTable}
            className={className}
            endpoint="meta/cell-components/gde-ids/available"
            searchKey="gde_id"
            disabled={disabled}
            error={error}
            onBlur={onBlur}
            onChange={_onChange}
            value={value}
            pendingChange={pendingChange}
            freeSolo={true}
          />
        );
      case "warning_message":
        return null;
      default:
        return noTable ? (
          <Box px={4} py={2}>
            No UI defined for {uiField["type"]}
          </Box>
        ) : (
          <ConditionCell>
            <Box px={4} py={2}>
              No UI defined for {uiField["type"]}
            </Box>
          </ConditionCell>
        );
    }
  }
);

export default TdCell;
