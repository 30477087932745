import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import uniq from "lodash/uniq";
import Button from "../../../components/Button";
import colors from "../../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "../../../components/Modal";
import type { RootState } from "../../../store";
import type { MetadataState } from "../slice";
import { generateTest, resetGenerateTest } from "../slice";
import ChannelDropdown from "../../channels/ChannelDropdown";
import { intersection, isEmpty } from "lodash";
import { Autocomplete, FormControl, Input, TextField } from "@mui/material";
import {
  EIS_TEST_TYPE_IDS,
  TEST_TYPE_LABELS,
  STD_TEST_TYPE_LABELS,
  PREFLIGHT_TEST_TYPE_LABEL,
} from "../../../utils/labels";
import TestStandDropdown from "../../test-stands/TestStandDropdown";
import { showTestStandOptions } from "../../../utils/testStandRules";

type Props = {
  open: boolean;
  onClose: () => void;
  cell_ids?: number[];
  conditionIdsToCellIdsForMetadataFetch: { [key: string]: string[] };
  reserved_channel: string;
  reserved_test_stand?: string;
  executor: User | null;
  enable_standard_tests?: boolean;
  module_id?: string;
  selectedCells?: Cell[];
  isFullScale?: boolean;
};

const BOL_EIS_TEST = STD_TEST_TYPE_LABELS.find(
  (_testType) => _testType.id === EIS_TEST_TYPE_IDS.eis_bol
);
const CYCLE_TEST = STD_TEST_TYPE_LABELS.find(
  (_testType) => _testType.id === "cycle_test"
);

const labelClasses = {
  color: "textSecondary",
  className: "small",
};

const TestModal = ({
  open,
  onClose,
  cell_ids,
  conditionIdsToCellIdsForMetadataFetch,
  reserved_channel,
  reserved_test_stand,
  executor,
  enable_standard_tests = true,
  module_id,
  selectedCells,
  isFullScale = false,
}: Props) => {
  // Metadata state in redux
  const dispatch = useDispatch();
  const {
    status: { onTest: status },
    error: { onTest: error },
    fileNamesRecentlyGenerated,
    ui,
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);
  const metadataPageLoaded = ui && ui[0];
  const cellIds = useMemo(
    () =>
      cell_ids ||
      selectedCells?.map((selectedCell) => selectedCell.cell_id) ||
      [],
    [cell_ids, selectedCells]
  );
  const multipleCells = cellIds.length > 1;
  const multipleTests = fileNamesRecentlyGenerated.length > 1;

  const [channel, setChannel] = useState<Channel | null>(null);
  const [testStand, setTestStand] = useState<TestStand | null>(null);
  const [adapterCableTypeOpts, setAdapterCableTypeOpts] = useState<string[]>(
    []
  );
  const [specifiedCableTypes, setSpecifiedCableTypes] = useState<
    string[] | null
  >(null);
  const [adapterCableType, setAdapterCableType] = useState<{
    id: string;
    label: string;
  } | null>(null);
  const [h2GasFlowRate, setH2GasFlowRate] = useState("");
  const [testType, setTestType] = useState<{
    id: string;
    label: string;
  } | null>(null);

  // Generation workflow
  const onGenerateTest = () => {
    let test_data: any = {
      cell_ids: cellIds,
      channel,
      testStand,
      executor,
      conditionIdsToRequestedCellIds: conditionIdsToCellIdsForMetadataFetch,
      test_type: testType!,
      adapter_cable_type: adapterCableType && adapterCableType.id,
      h2GasFlowRate,
    };

    module_id && (test_data["module_id"] = module_id);

    dispatch(generateTest(test_data));
  };

  // Ensure that preflight checks aren't the default as soon as
  // standard tests are available
  useEffect(() => {
    setTestType(
      enable_standard_tests ? CYCLE_TEST! : PREFLIGHT_TEST_TYPE_LABEL
    );
  }, [enable_standard_tests]);

  // Post-generation workflow
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!open) {
      dispatch(resetGenerateTest());
      setChannel(null);
      setTestType(null);
      setCopied(false);
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (!!testType) return;
    if (cellIds.length > 1) {
      setTestType(
        enable_standard_tests ? CYCLE_TEST! : PREFLIGHT_TEST_TYPE_LABEL
      );
      return;
    }
    if (metadataPageLoaded) {
      const cellOnTest = !!(ui[0] as ConditionMetadata).cells.find(
        (cell) => cell.cell_id === cellIds[0]
      )?.channel;
      const defaultToBolEisTest =
        !!(ui[0] as ConditionMetadata).on_test__eis_testing_beginning_of_life &&
        !cellOnTest;
      if (defaultToBolEisTest && enable_standard_tests) {
        setTestType(BOL_EIS_TEST!);
      } else {
        setTestType(
          enable_standard_tests ? CYCLE_TEST! : PREFLIGHT_TEST_TYPE_LABEL
        );
      }
    }
  }, [
    ui,
    testType,
    enable_standard_tests,
    adapterCableTypeOpts,
    metadataPageLoaded,
    cellIds,
  ]);

  useEffect(() => {
    if (isEmpty(adapterCableTypeOpts) && metadataPageLoaded) {
      const cableTypesSpecified = ui
        .map((_ui) =>
          "on_test__adapter_cable_type" in _ui
            ? _ui.on_test__adapter_cable_type
            : null
        )
        .filter((cableType) => !!cableType);
      if (cableTypesSpecified.length > 0) {
        setSpecifiedCableTypes(uniq(cableTypesSpecified as string[]));
        const onTestFields = ui
          .map((_uiObj) =>
            "fields" in _uiObj
              ? _uiObj.fields[0].items.find(({ title }) => title === "On-Test")
              : null
          )
          .filter((onTestField) => !!onTestField);
        const adapterTypeFields = onTestFields
          .map((onTestField) =>
            onTestField!.fields.find(
              ({ id }) => id === "on_test__adapter_cable_type"
            )
          )
          .filter((adapterTypeField) => !!adapterTypeField);
        const firstAdapterTypeField =
          adapterTypeFields &&
          adapterTypeFields.length > 0 &&
          adapterTypeFields[0];
        if (firstAdapterTypeField && firstAdapterTypeField.type === "options") {
          setAdapterCableTypeOpts(firstAdapterTypeField.options);
        }
      }
    }
  }, [ui, adapterCableTypeOpts, metadataPageLoaded]);

  const specifiedH2GasFlowRates = useMemo(() => {
    if (!metadataPageLoaded) return [];
    const uiObjsWithMatchingCells = ui.filter((uiObj_) => {
      return (
        "on_test__h2_sweep_gas_flow_rate" in uiObj_ &&
        intersection(
          uiObj_.cells.map((cell_) => cell_.cell_id),
          cellIds
        ).length > 0
      );
    });
    return uiObjsWithMatchingCells.map(
      (uiObj_) => (uiObj_ as ConditionMetadata)!.on_test__h2_sweep_gas_flow_rate
    );
  }, [metadataPageLoaded, ui, cellIds]);
  const showH2GasFlowRateInput =
    specifiedH2GasFlowRates.length > 0 && enable_standard_tests;

  const renderChannelOrTestStandSelection = () => {
    const showTestStandSelection =
      (metadataPageLoaded || (selectedCells && selectedCells.length > 0)) &&
      showTestStandOptions({
        reserve_test_stands: metadataPageLoaded
          ? ui[0].reserve_test_stands
          : selectedCells![0].cell_type.reserve_test_stands,
        module_id: module_id ? parseInt(module_id) : null,
        override_test_stands: metadataPageLoaded
          ? ui[0].override_test_stands
          : selectedCells![0].condition.override_test_stands,
      });
    if (showTestStandSelection) {
      return (
        <>
          <Box mt={6} mb={2} display="flex" alignItems="flex-end">
            <Typography {...labelClasses}>Reserved Test Stand</Typography>
            <Box ml={2}>
              <Typography color="textPrimary">
                {reserved_test_stand || "-"}
              </Typography>
            </Box>
          </Box>
          <Box mt={6} mb={2} display="flex" alignItems="flex-end">
            <Typography {...labelClasses}>
              Test Stand <span style={{ color: colors.accent.red }}>*</span>
            </Typography>
          </Box>
          <TestStandDropdown
            value={testStand}
            onChange={(selectedTestStand) => setTestStand(selectedTestStand)}
          />
        </>
      );
    }
    return (
      <>
        <Box mt={6} display="flex" alignItems="flex-end">
          <Typography {...labelClasses}>
            Channel ID <span style={{ color: colors.accent.red }}>*</span>
          </Typography>
        </Box>
        <Box mb={2} display="flex" alignItems="flex-end">
          <Typography {...labelClasses} sx={{ fontStyle: "italic" }}>
            reserved channel:
          </Typography>
          <Box ml={2}>
            <Typography color="textPrimary">
              {reserved_channel || "-"}
            </Typography>
          </Box>
        </Box>
        <ChannelDropdown
          allowQueueing={false}
          value={channel}
          cells={cellIds.map((cellId_) => Number(cellId_))}
          onChange={(channel) => setChannel(channel)}
          eisTest={
            !!testType && Object.values(EIS_TEST_TYPE_IDS).includes(testType.id)
          }
          reservedChannelName={enable_standard_tests ? reserved_channel : null}
          testModal
        />
      </>
    );
  };

  const renderSpecifiedH2GasFlowRateInput = () => {
    if (!showH2GasFlowRateInput) return null;
    return (
      <>
        <Box mt={6}>
          <Typography {...labelClasses}>
            H2 sweep gas flow rate (sccm){" "}
            <span style={{ color: colors.accent.red }}>*</span>
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography {...labelClasses}>
            <span style={{ fontStyle: "italic", marginRight: 4 }}>
              specified:
            </span>
            <span style={{ color: colors.text.primary }}>
              {specifiedH2GasFlowRates!.join(", ")}
            </span>
          </Typography>
        </Box>
        <Box>
          <FormControl style={{ width: "100%", height: "100%" }}>
            <Box>
              <Input
                className="small"
                disableUnderline
                onChange={(e) => setH2GasFlowRate(e.target.value)}
                value={h2GasFlowRate}
                inputProps={{ maxLength: 100 }}
              />
            </Box>
          </FormControl>
        </Box>
        <Box mt={2} mb={4} display="flex" alignItems="flex-end">
          {specifiedH2GasFlowRates!.length > 1 && (
            <Box display="flex" alignItems="flex-end">
              <Typography {...labelClasses}>
                To enter different flow rates per cell, put cells on test
                individually.
              </Typography>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <Modal open={open} onClose={status === "succeeded" ? undefined : onClose}>
      <>
        <Typography variant="h2">
          {status === "succeeded"
            ? `Test${multipleTests ? "s" : ""} generated`
            : `Generate test${multipleCells ? "s" : ""}`}
        </Typography>

        {status !== "succeeded" ? (
          <>
            <Box mt={6} mb={2} display="flex" alignItems="flex-end">
              <Typography {...labelClasses}>
                {`Cell ID${multipleCells ? "s" : ""}`}
              </Typography>
              <Box ml={2}>
                <Typography color="textPrimary">
                  {cellIds.join(", ").padStart(6, "0")}
                </Typography>
              </Box>
            </Box>
            {adapterCableTypeOpts.length > 0 && enable_standard_tests && (
              <>
                <Box mt={6}>
                  <Typography {...labelClasses}>
                    Adapter cable type{" "}
                    <span style={{ color: colors.accent.red }}>*</span>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography {...labelClasses}>
                    <span style={{ fontStyle: "italic", marginRight: 4 }}>
                      specified:
                    </span>
                    <span style={{ color: colors.text.primary }}>
                      {specifiedCableTypes!.join(", ")}
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Autocomplete
                    options={adapterCableTypeOpts.map((adapterOption) => ({
                      id: adapterOption,
                      label: adapterOption,
                    }))}
                    value={adapterCableType || { id: "", label: "" }}
                    onChange={(_event, data) => {
                      setAdapterCableType(data);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        color="secondary"
                      />
                    )}
                    renderOption={(props, value) => (
                      <li {...props}>
                        <Box px={4} py={2}>
                          {value.label}
                        </Box>
                      </li>
                    )}
                  />
                </Box>
                {specifiedCableTypes!.length > 1 && (
                  <Box mt={6} mb={2} display="flex" alignItems="flex-end">
                    <Typography {...labelClasses}>
                      To enter separate adapter cable types per cell, put cells
                      on test individually.
                    </Typography>
                  </Box>
                )}
              </>
            )}
            {renderSpecifiedH2GasFlowRateInput()}
            <Box mt={6} mb={2} display="flex" alignItems="flex-end">
              <Typography {...labelClasses}>
                Test Type <span style={{ color: colors.accent.red }}>*</span>
              </Typography>
            </Box>
            <Box>
              <Autocomplete
                options={
                  enable_standard_tests
                    ? isFullScale
                      ? TEST_TYPE_LABELS
                      : STD_TEST_TYPE_LABELS
                    : [PREFLIGHT_TEST_TYPE_LABEL]
                }
                value={testType || { id: "", label: "" }}
                disableClearable
                onChange={(_event, data) => {
                  setChannel(null);
                  setTestType(data);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  />
                )}
                renderOption={(props, value) => (
                  <li {...props}>
                    <Box px={4} py={2}>
                      {value.label}
                    </Box>
                  </li>
                )}
              />
            </Box>
            {renderChannelOrTestStandSelection()}
            {channel ? (
              <Box mt={6} mb={2} display="flex" alignItems="flex-end">
                <Typography {...labelClasses}>Incubator Pos ID</Typography>
                <Box ml={6}>
                  <Typography color="textPrimary">
                    {channel.position.name}
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </>
        ) : !copied ? (
          <Box mt={6} mb={2}>
            {multipleTests
              ? "Click below to copy the test filenames."
              : "Please copy the test filename and paste it into the cycling software."}
          </Box>
        ) : (
          <>
            <Box mt={6} mb={2}>
              {multipleTests
                ? null
                : "Please paste the filename into the cycling software and confirm below."}
            </Box>
            <Box mt={6} mb={2} display="flex" alignItems="flex-end">
              <Typography {...labelClasses} alignSelf="center">
                {`Test filename${multipleTests ? "s" : ""}`}
              </Typography>
              <Box ml={6}>
                {fileNamesRecentlyGenerated.map((testFileName) => (
                  <Typography color="textPrimary" key={testFileName}>
                    {testFileName}
                  </Typography>
                ))}
              </Box>
            </Box>
          </>
        )}
        {status !== "succeeded" ? (
          <Box mt={6} display="flex" justifyContent="space-between">
            <div className="small" style={{ color: colors.accent.red }}>
              {error}
            </div>
            <Button
              size="small"
              color="primary"
              onClick={onGenerateTest}
              endIcon={
                status === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={
                !(channel || testStand) ||
                !testType ||
                status === "loading" ||
                (!isEmpty(specifiedCableTypes) &&
                  !adapterCableType &&
                  enable_standard_tests) ||
                (showH2GasFlowRateInput && !h2GasFlowRate)
              }
            >
              {`Generate Test${multipleCells ? "s" : ""}`}
            </Button>
          </Box>
        ) : (
          <Box mt={6} display="flex" justifyContent="space-between">
            <Button
              size="small"
              color="cta"
              disabled={isEmpty(fileNamesRecentlyGenerated)}
              onClick={() => {
                navigator.clipboard.writeText(
                  fileNamesRecentlyGenerated.join(", ")
                );
                setCopied(true);
              }}
            >
              {`Copy test filename${multipleTests ? "s" : ""}`}
            </Button>
            {copied ? (
              <Button size="small" color="primary" onClick={onClose}>
                Confirm
              </Button>
            ) : null}
          </Box>
        )}
      </>
    </Modal>
  );
};

export default TestModal;
