import React, { useCallback, useState } from "react";
import {
  GridRenderEditCellParams,
  GridRowModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import client from "../../api";
import { useDebouncedCallback } from "use-debounce";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";

interface AutocompleteEditInputCellProps {
  params: GridRenderEditCellParams;
  allElectrodeFields: {
    field: string;
    headerName: string;
    width?: number;
    type?: string;
    requiredForStepComplete?: boolean;
  }[];
  options: any[] | undefined;
  freeSolo?: boolean;
  multiple?: boolean;
  getOptionLabel?: (option: any) => string;
  value: string | null;
}

const AutocompleteEditInputCell = ({
  params,
  // List of dicts of field IDs, header names, etc.
  allElectrodeFields,
  value,
  options,
  freeSolo,
  getOptionLabel,
}: AutocompleteEditInputCellProps) => {
  const apiRef = useGridApiContext();
  const [cellSerialNumberOptions, setCellSerialNumberOptions] = useState<
    ExternalIdentifierData[] | null
  >(null);

  const _handleCellSNLookup = useCallback(async (inputVal: string) => {
    try {
      const response = await client.get(
        `meta/external-identifier-data?entity_type=cell&removed_at__null=1&external_id__contains=${inputVal}`
      );
      setCellSerialNumberOptions(response.data);
    } catch (err) {}
  }, []);

  const handleModuleLookup = useDebouncedCallback(
    (inputVal: string) => _handleCellSNLookup(inputVal),
    400,
    {
      leading: true,
      trailing: true,
    }
  );
  return (
    <Autocomplete
      value={value}
      onBlur={() => setCellSerialNumberOptions(null)}
      onChange={(event_, newModuleIdentifiers) => {
        const newCellSn = newModuleIdentifiers ? newModuleIdentifiers.external_id : null;
        if (
          !!newCellSn &&
          allElectrodeFields
            .map((elecField) => elecField.field)
            .some(
              // Check that the new data matches required fields
              // and as either external_id or mass data.
              (fieldKey) =>
                newModuleIdentifiers[fieldKey] && (!!newModuleIdentifiers[fieldKey].external_id || !!newModuleIdentifiers[fieldKey].mass)
            )
        ) {
          // Unpack RPC response values for a cell and assign their
          // external_id or mass values into corresponding fields.
          const newRowVal = Object.keys(params.row).reduce(
            (newRowVal_, fieldName) => {
              var fieldVal: GridRowModel = params.row[fieldName];
              if (newModuleIdentifiers[fieldName]) {
                if (!!newModuleIdentifiers[fieldName].external_id) {
                    fieldVal = newModuleIdentifiers[fieldName].external_id;
                } else if (!!newModuleIdentifiers[fieldName].mass) {
                    fieldVal = newModuleIdentifiers[fieldName].mass;
                }
              }
              return {
                ...newRowVal_,
                [fieldName]: fieldVal,
              };
            },
            {}
          );
          apiRef.current.updateRows([newRowVal]);
        }
      }}
      onInputChange={(event_: React.SyntheticEvent, newValue) => {
        if (!options || options.length === 0) {
          handleModuleLookup(newValue);
        }
        apiRef.current.setEditCellValue({
          id: params.id,
          field: params.field,
          value: newValue,
        });
      }}
      fullWidth
      options={cellSerialNumberOptions || options || []}
      freeSolo={freeSolo}
      autoHighlight
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value_) => option.external_id === value_}
      renderInput={(inputParams) => (
        <TextField {...inputParams} error={params.error} />
      )}
      renderOption={(props, opt) => (
        <li {...props}>
          <Box p={2} width="100%">
            <Typography
              color="textPrimary"
              className={opt.external_id ? "small" : undefined}
            >
              {opt.external_id}
            </Typography>
            {opt.secondary_external_id ? (
              <Typography color="textSecondary" className="tiny">
                {!!Number(opt.secondary_external_id)
                  ? `Position ${opt.secondary_external_id}`
                  : opt.secondary_external_id}
              </Typography>
            ) : null}
          </Box>
        </li>
      )}
    />
  );
};

export default AutocompleteEditInputCell;
