import isNil from "lodash/isNil";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import colors from "../../theme/colors";
import {
  cellIdToString,
  channelStatusToString,
  channelInfraStatusToString,
} from "../../utils/labels";
import Link from "../../components/Link";
import DotIcon from "../../icons/Dot";
import SyncWarningIcon from "../../icons/SyncWarning";
import ExperimentLink from "../../components/ExperimentLink";
import FlagTableCell from "../../components/table/FlagTableCell";
import PlusCircle from "../../icons/PlusCircle";
import { Tooltip } from "@mui/material";

type Props = {
  dataKey: FalconKey<Channel>;
  channel: Channel;
};

type InfraStatusExplanationLookup = {
  [infraStatus: string]: string | undefined;
};
export const EXPLANATIONS_OF_SOME_INFRA_STATUSES: InfraStatusExplanationLookup =
  {
    W: "Cannot troubleshoot channel further with current resources.",
    T: "Incubator temperature change in progress - check the new temperature before reserving.",
    "2": "This channel does not have H2 sensing capabilities, but it can support a standard Pegasus cell.",
    V: "This channel has historically had noisy aux voltage measurements - only reserve for cells that don't require high quality aux voltage data.",
    G: "Channel is currently in use with another main channel. Cannot be reserved.",
    F: "Unknown reason for low function status - contact test execution before reserving.",
    B: "Incubator temperature not within spec.",
    P: "This channel has historically had unreliable thermocouple measurements - only reserve for cells that don't require thermocouple data.",
    N: "Channel wiring or mapping altered for experiment.",
  };
const CELL_INFO_TOOLTIP_TEXT =
  "This channel allows multiple cells to be on test at the same time. Cell and test-specific information is from the most recent cell put on test for the channel.";

const ChannelTableCell = ({ dataKey, channel }: Props) => {
  switch (dataKey) {
    case "position__name":
      return (
        <TableCell scope="row" padding="none">
          {channel.position.name || "-"}
        </TableCell>
      );
    case "position__shelf":
      return (
        <TableCell scope="row" padding="none">
          {channel.position.shelf || "-"}
        </TableCell>
      );
    case "channel__fullname":
      const renderMultiCellChannelInfo = () => {
        return (
          <Tooltip arrow title={CELL_INFO_TOOLTIP_TEXT}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <PlusCircle
                style={{ maxHeight: 16, color: colors.accent.primary }}
              />
            </span>
          </Tooltip>
        );
      };
      return (
        <TableCell>
          <div style={{ display: "flex" }}>
            {channel.channel.fullname || "-"}
            {channel.channel.max_tests > 1 && renderMultiCellChannelInfo()}
          </div>
        </TableCell>
      );
    case "channel__status":
      return (
        <TableCell>
          <Box display="flex" alignItems="center">
            <DotIcon
              style={{
                width: 8,
                height: 8,
                color:
                  channel.channel.status === "O"
                    ? colors.accent.cta
                    : channel.channel.status === "I"
                    ? colors.accent.green
                    : colors.accent.red,
              }}
            />
            <Box ml={2}>{channelStatusToString(channel.channel.status)}</Box>
          </Box>
        </TableCell>
      );
    case "channel__infra_status":
      const infraStatus = channel.channel.infra_status;
      const tooltipText = EXPLANATIONS_OF_SOME_INFRA_STATUSES[infraStatus];
      return (
        <TableCell>
          {tooltipText ? (
            <Tooltip arrow placement="left" title={tooltipText}>
              <div>{channelInfraStatusToString(infraStatus)}</div>
            </Tooltip>
          ) : (
            channelInfraStatusToString(infraStatus)
          )}
        </TableCell>
      );

    case "experiment__exp_id":
      return channel.experiment.exp_id ? (
        <TableCell padding="none">
          <ExperimentLink
            exp_id={channel.experiment.exp_id}
            link={channel.experiment.link}
            description={channel.experiment.description}
          />
        </TableCell>
      ) : (
        <TableCell>-</TableCell>
      );

    case "experiment__description":
      return <TableCell>{channel.experiment.description || "-"}</TableCell>;

    case "experiment__project":
      return <TableCell>{channel.experiment.project.name || "-"}</TableCell>;

    case "owner__name":
      return <TableCell>{channel.owner.name || "-"}</TableCell>;

    case "reserved_cell__cell_id":
      return (
        <TableCell>
          {channel.reserved_cell.cell_id ? (
            <Link
              to={`/cells/${channel.reserved_cell.cell_id}`}
              color="secondary"
              onClick={(clickEvent: React.MouseEvent) => {
                clickEvent.stopPropagation();
              }}
              state={{ from: "/infrastructure/channels" }}
            >
              {cellIdToString(channel.reserved_cell.cell_id)}
              {channel.reserved_cell.is_channel_change ? (
                <SyncWarningIcon
                  style={{
                    width: 16,
                    height: 16,
                    color: colors.accent.burntYellow,
                    marginLeft: 6,
                  }}
                  titleAccess={'The reservation is for a channel change.'}
                />
                ) : ""
              }
            </Link>
          ) : (
            "-"
          )}
        </TableCell>
      );

    case "cell__cell_id":
      return (
        <TableCell>
          {channel.cell.cell_id ? (
            <>
              <Link
                to={`/cells/${channel.cell.cell_id}`}
                color="secondary"
                onClick={(clickEvent: React.MouseEvent) => {
                  clickEvent.stopPropagation();
                }}
                state={{ from: "/infrastructure/channels" }}
              >
                {cellIdToString(channel.cell.cell_id)}
              </Link>
              {channel.cell.is_module_cell && " (MOD)"}
            </>
          ) : (
            "-"
          )}
        </TableCell>
      );

    case "full__cell_component__serial_number":
      return (
        <TableCell>
          {"serial_number" in channel.cell && channel.cell.serial_number
            ? channel.cell.serial_number
            : "-"}
        </TableCell>
      );

    case "test_meta__ready_off__completed_at":
      return (
        <TableCell>
          <Box display="flex" alignItems="center">
            {!!channel.test_meta.ready_off__completed_at ? (
              <SyncWarningIcon
                style={{
                  color: colors.accent.violet,
                  width: 18,
                  height: 18,
                }}
              />
            ) : null}
          </Box>
        </TableCell>
      );

    case "cell_condition__cell_type":
      return <TableCell>{channel.cell_condition.cell_type || "-"}</TableCell>;

    case "cell_condition__cell_assembly":
      return (
        <TableCell>{channel.cell_condition.cell_assembly || "-"}</TableCell>
      );

    case "cell_condition__name":
      return <TableCell>{channel.cell_condition.name || "-"}</TableCell>;

    case "cell_condition__description":
      return <TableCell>{channel.cell_condition.description || "-"}</TableCell>;

    case "channel__pool":
      return (
        <TableCell align="center">{channel.channel.pool || "-"}</TableCell>
      );

    case "channel__temperature":
      return (
        <TableCell align="center">
          {isNil(channel.channel.temperature)
            ? "-"
            : `${channel.channel.temperature} °C`}
        </TableCell>
      );

    case "channel__airlines":
      return (
        <TableCell align="center">
          {isNil(channel.channel.airlines) ? "-" : channel.channel.airlines}
        </TableCell>
      );

    case "channel__water":
      return (
        <TableCell align="center">
          {isNil(channel.channel.water) ? "-" : channel.channel.water}
        </TableCell>
      );

    case "channel__aux_channels":
      return (
        <TableCell align="center">
          {isNil(channel.channel.aux_channels)
            ? "-"
            : channel.channel.aux_channels}
        </TableCell>
      );

    case "channel__aux_voltage_box_type":
      return (
        <TableCell align="center">
          {isNil(channel.channel.aux_voltage_box_type)
            ? "-"
            : channel.channel.aux_voltage_box_type}
        </TableCell>
      );

    case "channel__flow_valve_type":
      return (
        <TableCell align="center">
          {isNil(channel.channel.flow_valve_type)
            ? "-"
            : channel.channel.flow_valve_type}
        </TableCell>
      );

    case "channel__preferred_test_vehicle":
      return (
        <TableCell align="center">
          {isNil(channel.channel.preferred_test_vehicle)
            ? "-"
            : channel.channel.preferred_test_vehicle}
        </TableCell>
      );
    case "channel__yearly_calibration_date":
      return (
        <TableCell align="center">
          {isNil(channel.channel.yearly_calibration_date)
            ? "-"
            : channel.channel.yearly_calibration_date}
        </TableCell>
      );
    case "channel__kilobubbler_count":
      return (
        <TableCell align="center">
          {isNil(channel.channel.kilobubbler_count)
            ? "-"
            : channel.channel.kilobubbler_count}
        </TableCell>
      );

    case "channel__pressure_setpoint":
      return (
        <TableCell align="center">
          {isNil(channel.channel.pressure_setpoint)
            ? "-"
            : channel.channel.pressure_setpoint}
        </TableCell>
      );

    case "channel__hazelnut_installed":
      return isNil(channel.channel.hazelnut_installed) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[channel.channel.hazelnut_installed ? "check" : "times"]}
        />
      );
    case "channel__subscale_h2_interlock_installed":
      return isNil(channel.channel.subscale_h2_interlock_installed) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[
            channel.channel.subscale_h2_interlock_installed ? "check" : "times",
          ]}
        />
      );

    case "channel__thermal_uniformity_fans_installed":
      return isNil(channel.channel.thermal_uniformity_fans_installed) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[
            channel.channel.thermal_uniformity_fans_installed
              ? "check"
              : "times",
          ]}
        />
      );

    case "channel__cathode_switching":
      return isNil(channel.channel.cathode_switching) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[channel.channel.cathode_switching ? "check" : "times"]}
        />
      );

    case "channel__tc_channels":
      return (
        <TableCell align="center">
          {isNil(channel.channel.tc_channels)
            ? "-"
            : channel.channel.tc_channels}
        </TableCell>
      );

    case "channel__co2_scrubbing":
      return isNil(channel.channel.co2_scrubbing) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[channel.channel.co2_scrubbing ? "check" : "times"]}
        />
      );

    case "channel__humidity_control":
      return isNil(channel.channel.humidity_control) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[channel.channel.humidity_control ? "check" : "times"]}
        />
      );

    case "channel__tester_brand":
      return (
        <TableCell align="center">
          {isNil(channel.channel.tester_brand)
            ? "-"
            : channel.channel.tester_brand}
        </TableCell>
      );

    case "channel__hydrogen_monitoring":
      return isNil(channel.channel.hydrogen_monitoring) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[channel.channel.hydrogen_monitoring ? "check" : "times"]}
        />
      );

    case "channel__aux_voltage_monitoring":
      return isNil(channel.channel.aux_voltage_monitoring) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[channel.channel.aux_voltage_monitoring ? "check" : "times"]}
        />
      );

    case "channel__eis_functionality":
      return isNil(channel.channel.eis_functionality) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell
          icons={[channel.channel.eis_functionality ? "check" : "times"]}
        />
      );

    case "channel__low_current_range":
      return (
        <TableCell align="center">
          {isNil(channel.channel.low_current_range)
            ? "-"
            : `${channel.channel.low_current_range} A`}
        </TableCell>
      );

    case "channel__high_current_range":
      return (
        <TableCell align="center">
          {isNil(channel.channel.high_current_range)
            ? "-"
            : `${channel.channel.high_current_range} A`}
        </TableCell>
      );

    case "channel__min_voltage":
      return (
        <TableCell align="center">
          {isNil(channel.channel.min_voltage)
            ? "-"
            : `${channel.channel.min_voltage} V`}
        </TableCell>
      );

    case "channel__max_voltage":
      return (
        <TableCell align="center">
          {isNil(channel.channel.max_voltage)
            ? "-"
            : `${channel.channel.max_voltage} V`}
        </TableCell>
      );

    case "incubator__name":
      return (
        <TableCell align="center">
          {isNil(channel.incubator.name) ? "-" : `${channel.incubator.name}`}
        </TableCell>
      );

    case "test_stand__name":
      return (
        <TableCell align="center">
          {isNil(channel.test_stand.name) ? "-" : `${channel.test_stand.name}`}
        </TableCell>
      );

    case "incubator__bay":
      return (
        <TableCell align="center">
          {isNil(channel.incubator.bay) ? "-" : `${channel.incubator.bay}`}
        </TableCell>
      );

    default:
      return <TableCell>{dataKey} not implemented</TableCell>;
  }
};

export default ChannelTableCell;
