import React, { useState } from "react";
import { useDispatch } from "react-redux";
import uniq from "lodash/uniq";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { cellsSlices } from "./slice";
import ReserveChannelsModal from "./ReserveChannelsModal";
import ReserveTestStandsModal from "./ReserveTestStandsModal";
import { showTestStandOptions } from "../../utils/testStandRules";

type Props = {
  stateKey: keyof typeof cellsSlices;
  selected: Cell[];
};

/* Group of menu items which launch the channel reservation modal. */
const ReservationMenu = ({ stateKey, selected }: Props) => {
  const handleButtonClose = (
    event: React.MouseEvent<
      HTMLLIElement | HTMLButtonElement | HTMLAnchorElement
    >
  ) => {
    event.stopPropagation();
  };

  const handleClearChannelReservation = () => {
    dispatch(cellsSlices[stateKey].cancelReservations(selected));
  };

  const handleClearTestStandReservation = () => {
    dispatch(cellsSlices[stateKey].cancelTestStandReservations(selected));
  };

  const dispatch = useDispatch();

  const [reserveChannelsOpen, setReserveChannelsOpen] = useState(false);
  const [reserveTestStandsOpen, setReserveTestStandsOpen] = useState(false);

  const hasConflict =
    uniq(selected.map(({ condition }) => condition.cell_assembly)).length !==
      1 ||
    selected.some(
      ({
        condition: { override_test_stands },
        cell_type: { reserve_test_stands },
        module: { module_id },
      }) =>
        showTestStandOptions({
          reserve_test_stands,
          module_id,
          override_test_stands,
        })
    );

  const allSelectedHaveTestStandRes =
    selected.length > 0 &&
    selected.every(
      ({ reserved_channel: { test_stand_id } }) => !!test_stand_id
    );

  return (
    <>
      <Tooltip
        arrow
        placement="left"
        title="Please select only 1 cell assembly type. Selected cell assembly must not require Full-scale Test Stand reservations."
        disableHoverListener={!hasConflict}
        disableFocusListener={!hasConflict}
      >
        <span>
          <MenuItem
            disabled={hasConflict}
            onClick={(e) => {
              handleButtonClose(e);
              setReserveChannelsOpen(true);
            }}
          >
            Reserve channels
          </MenuItem>
        </span>
      </Tooltip>
      {selected.length > 0 &&
        selected.every(
          ({
            condition: { override_test_stands },
            cell_type: { reserve_test_stands },
            module: { module_id },
          }) =>
            // Show test stand flow if the cell_type.reserve_test_stands specifies it,
            // see table definition.
            showTestStandOptions({
              reserve_test_stands,
              override_test_stands,
              module_id,
            })
        ) && (
          <MenuItem
            onClick={(e) => {
              handleButtonClose(e);
              setReserveTestStandsOpen(true);
            }}
          >
            Reserve test stands
          </MenuItem>
        )}
      <MenuItem
        onClick={(e) => {
          handleButtonClose(e);
          dispatch(
            cellsSlices[stateKey].modifyCells({
              cells: selected,
              data: { reserved_required: false },
            })
          );
        }}
      >
        Skip channel reservation
      </MenuItem>
      <MenuItem
        disabled={
          // some selected cells don't have reserved channel,
          // or the selected cells' reserved channels are a
          // mix of test-stand/ sans-test-stand channels.
          !selected.some(
            ({ reserved_channel: { channel } }) => channel?.fullname
          ) ||
          ![0, selected.length].includes(
            selected.filter(
              ({ reserved_channel: { test_stand_id } }) => !!test_stand_id
            ).length
          )
        }
        onClick={(e) => {
          handleButtonClose(e);
          allSelectedHaveTestStandRes
            ? handleClearTestStandReservation()
            : handleClearChannelReservation();
        }}
      >
        {`Clear ${allSelectedHaveTestStandRes ? "Test Stand " : ""}Reservation${
          selected.length > 1 ? "s" : ""
        }`}
      </MenuItem>

      <ReserveChannelsModal
        open={reserveChannelsOpen}
        onClose={() => setReserveChannelsOpen(false)}
        stateKey={stateKey}
        selected={selected}
      />
      <ReserveTestStandsModal
        open={reserveTestStandsOpen}
        onClose={() => setReserveTestStandsOpen(false)}
        stateKey={stateKey}
        selected={selected}
      />
    </>
  );
};

export default ReservationMenu;
