import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import client from "../../api";
import colors from "../../theme/colors";
import ChannelDropdownFilters from "./ChannelDropdownFilters";
import { isNull, omit } from "lodash";
import { useDebouncedCallback } from "use-debounce";

export type ChannelOptionFilters = Partial<{
  cell_ids: number[];
  pool: string;
  temperature: string;
  airlines: string;
  airlines__null: "1";
  tester_brand: string;
  cathode_switching: "1";
  cathode_switching__null: "1";
  tc_channels: "1";
  tc_channels__null: "1";
  co2_scrubbing: "0" | "1";
  humidity_control: "0" | "1";
  hazelnut_installed: "0" | "1";
  subscale_h2_interlock_installed: "0" | "1";
  hydrogen_monitoring: "0" | "1";
  high_current_range__lte: string;
  low_current_range__gte: string;
  max_voltage__lte: string;
  min_voltage__gte: string;
  aux_voltage_monitoring: "0" | "1";
  eis_functionality: "0" | "1";
  pegasus: "0" | "1";
  arion: "0" | "1";
  marblefeet: "0" | "1";
  elbow: "0" | "1";
  babyfeet: "0" | "1";
  whopper_jr: "0" | "1";
}>;

type Props = {
  value: Channel | null;
  onChange: (channel: Channel | null) => void;
  allowQueueing?: boolean;
  hideTestStandChannels?: boolean;
  cells?: number[];
  onBlur?: () => void;
  error?: boolean;
  eisTest?: boolean;
  reservedChannelName?: string | null;
  testModal?: boolean;
};

const EIS_FUNCTIONALITY = "eis_functionality";
const RESERVABLE_INFRA_STATUSES: ChannelInfraStatus[] = [
  "O",
  "2",
  "V",
  "F",
  "B",
  "P",
];
const ON_TEST_INFRA_STATUSES: ChannelInfraStatus[] = [
  ...RESERVABLE_INFRA_STATUSES,
  "C",
];

const ChannelDropdown = forwardRef(
  (
    {
      value,
      onChange,
      allowQueueing,
      hideTestStandChannels,
      cells,
      onBlur,
      error,
      eisTest = false,
      reservedChannelName,
      testModal = false,
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<Channel[]>([]);
    const [search, setSearch] = useState(reservedChannelName || "");
    const [filters, setFilters] = useState<ChannelOptionFilters | null>(null);
    const eisTestValRef = useRef<boolean | null>(null);

    const _handleLookup = useCallback(async () => {
      if ((!open && !reservedChannelName) || (!search && !filters)) {
        return;
      }

      setLoading(true);

      try {
        let endpoint = `meta/channels/advanced?`;

        endpoint += `&channel__infra_status=${(testModal
          ? ON_TEST_INFRA_STATUSES
          : RESERVABLE_INFRA_STATUSES
        ).join(",")}`;

        if (!allowQueueing) {
          endpoint += `&channel__status=${"O" as ChannelStatus}`;
        }

        if (allowQueueing) {
          endpoint += `&allow_queueing_ready_off_channels=1`;
        }

        if (hideTestStandChannels) {
          endpoint += `&position__test_stand_id__null=1`;
        }

        if (search) {
          endpoint += `&channel__fullname__contains=${search}`;
        }

        if (filters) {
          Object.keys(filters).forEach((key) => {
            if (key === "cell_ids") {
              endpoint += filters[key]!.map(
                (cell_id) => `&${key}=${cell_id}`
              ).join("");
            } else {
              endpoint += `&${key}=${
                filters[key as keyof ChannelOptionFilters]
              }`;
            }
          });
        }

        const response: { data: Channel[] } = await client.get(endpoint);
        setOptions(response.data);
        if (
          !!reservedChannelName &&
          response.data.length === 1 &&
          response.data.every(
            (channel_) => channel_.channel.fullname === reservedChannelName
          )
        ) {
          onChange(response.data[0]);
        }
      } catch (err) {
        setOptions([]);
      }

      setLoading(false);
    }, [
      search,
      filters,
      hideTestStandChannels,
      open,
      testModal,
      allowQueueing,
      reservedChannelName,
      onChange,
    ]);

    const handleLookup = useDebouncedCallback<() => void>(_handleLookup, 400, {
      leading: true,
      trailing: true,
    });

    useEffect(() => {
      if (open || (!!reservedChannelName && search === reservedChannelName)) {
        handleLookup();
      }
    }, [open, search, filters, handleLookup, reservedChannelName]);

    useEffect(() => {
      // use eisTestValRef.current to track if eisTest value has changed
      eisTestValRef.current = eisTest;
    }, [eisTest]);
    const prevEisTestVal = eisTestValRef.current;

    useEffect(() => {
      if (filters && (isNull(prevEisTestVal) || prevEisTestVal !== eisTest)) {
        if (eisTest) {
          setFilters({ ...filters, ...{ [EIS_FUNCTIONALITY]: "1" } });
        } else {
          setFilters(omit(filters, [EIS_FUNCTIONALITY]));
        }
      }
    }, [eisTest, filters, setFilters, prevEisTestVal]);

    return (
      <div style={{ width: "100%" }}>
        <Autocomplete
          ref={ref}
          options={options}
          getOptionLabel={(option) => option.channel.fullname}
          isOptionEqualToValue={(option, value) =>
            option.channel.channel_id === value.channel.channel_id
          }
          value={value?.channel.fullname ? value : null}
          onChange={(e, data) => {
            setOpen(false);
            onChange(data);
          }}
          loading={loading}
          noOptionsText={
            search || filters
              ? "No results found."
              : "Start typing or select filters..."
          }
          open={open}
          onBlur={onBlur}
          onFocus={() => setOpen(true)}
          PaperComponent={(props) => (
            <ChannelDropdownFilters
              {...props}
              cells={cells}
              value={filters}
              onChange={setFilters}
            />
          )}
          inputValue={search}
          onInputChange={(e, value) => {
            if (e) {
              setOpen(true);
            }
            let newVal = value;
            if (!e && reservedChannelName) {
              newVal = reservedChannelName;
            }
            setSearch(newVal);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(!open);
                      }}
                    >
                      {params.InputProps.endAdornment}
                    </span>
                  </>
                ),
                error,
              }}
            />
          )}
          renderOption={(props, opt) => {
            const statusesToReport = [];
            if (opt.reserved_cell.cell_id) statusesToReport.push("reserved");
            if (
              opt.test_meta.test_meta_id &&
              !opt.test_meta.ready_off__completed_at
            )
              statusesToReport.push("on test");
            if (opt.channel.max_tests > 1)
              statusesToReport.push("module channel");

            if (opt.channel.infra_status === "C")
              statusesToReport.push("commissioning");

            return (
              <li {...props}>
                <Box px={4} py={2} width="100%">
                  {opt.channel.fullname}
                  {statusesToReport.length > 0 ? (
                    <em style={{ color: colors.accent.red }}>
                      {" "}
                      {`(${statusesToReport.join(", ")})`}
                    </em>
                  ) : null}
                </Box>
              </li>
            );
          }}
        />
      </div>
    );
  }
);

export default ChannelDropdown;
