import { useFormContext, useForm, Controller, useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Button from "../../../components/Button";
import Banner from "../../../components/Banner";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  autoCommitCells,
  ConditionState,
  resetAutoCommit,
  resetSpecifyCondition,
  specifyConditions,
} from "../slice";
import { RootState } from "../../../store";
import { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import ExclamationCircleOutlined from "../../../icons/ExclamationCircleOutlined";
import colors from "../../../theme/colors";

type Props = {
  open: boolean;
  bypassCommit?: boolean;
  exp_id: number | undefined;
  onClose: () => void;
};

const SpecifyModal = ({ open, exp_id, onClose, bypassCommit }: Props) => {
  const {
    conditions,
    error: { autoCommit: autoCommitError, specify: specifyErrors },
    status: { autoCommit: autoCommitStatus, specify: specifyConditionsStatus },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);
  const [specifyingConditionIds, setSpecifyingConditionIds] = useState<
    number[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { getValues, formState } = useFormContext() || {};
  const { errors } = formState || { errors: {} };

  const { control, handleSubmit } = useForm();
  const state = useWatch({ control });

  const closeModalAfterSpecify = useCallback(() => {
    dispatch(resetSpecifyCondition());
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    if (
      open &&
      specifyingConditionIds.length > 0 &&
      ["succeeded", "failed"].includes(specifyConditionsStatus)
    ) {
      setIsLoading(false);
      if (specifyConditionsStatus === "succeeded") {
        if (bypassCommit) {
          const cells = conditions
            .filter(
              (condition) =>
                !!condition.cell_condition_id &&
                specifyingConditionIds.includes(condition.cell_condition_id)
            )
            .flatMap((condition) => condition.cells);
          dispatch(
            autoCommitCells({
              cell_ids: cells.map((cell) => cell.cell_id),
              exp_id: `${exp_id}`,
            })
          );
          setIsLoading(false);
          closeModalAfterSpecify();
        }
        closeModalAfterSpecify();
      }
      setSpecifyingConditionIds([]);
    }
  }, [
    specifyConditionsStatus,
    bypassCommit,
    dispatch,
    onClose,
    open,
    specifyErrors,
    closeModalAfterSpecify,
    conditions,
    exp_id,
    specifyingConditionIds,
  ]);

  const onSpecify = async (values: Record<number, boolean>) => {
    setIsLoading(true);
    const conditionIds = Object.keys(values)
      .filter((key) => values[parseInt(key)])
      .map((id) => parseInt(id));
    setSpecifyingConditionIds(conditionIds);
    dispatch(specifyConditions({ conditionIds, exp_id: `${exp_id}` }));
  };

  let labels;
  let conditionCount = 0;
  let hasSpecifiableConditions = false;

  if (open) {
    const { conditions = [] } = getValues();
    conditionCount = conditions.filter(
      (c: Condition) => c.status === "I"
    ).length;

    labels = conditions.map((condition: Condition, index: number) => {
      if (condition.status !== "I") {
        return null;
      }

      const disabled = errors["conditions"]
        ? !!errors["conditions"][index]
        : false;

      const label = !disabled ? (
        condition.name
      ) : (
        <Tooltip arrow title="This condition is missing required fields">
          <span>{condition.name}</span>
        </Tooltip>
      );

      hasSpecifiableConditions = !disabled || hasSpecifiableConditions;

      return (
        <div key={index}>
          <Controller
            control={control}
            name={`${condition.cell_condition_id}`}
            defaultValue={false}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    ref={ref}
                    color="secondary"
                    name={name}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                }
                label={
                  <Typography
                    color={disabled ? "textSecondary" : "textPrimary"}
                  >
                    {label}
                  </Typography>
                }
              />
            )}
          />
          <Divider />
        </div>
      );
    });
  }

  if (autoCommitError) {
    return (
      <Box
        position="fixed"
        style={{ top: 74, left: 254, width: "calc(100vw - 302px)" }}
        m={4}
        zIndex={10}
      >
        <Banner severity="error" onClose={() => resetAutoCommit()}>
          Error committing cells.
        </Banner>
      </Box>
    );
  }

  if (
    open &&
    !hasSpecifiableConditions &&
    !["loading", "succeeded"].includes(autoCommitStatus)
  ) {
    const conditionKeys = Object.keys(errors["conditions"] || {});
    const unspecConditions = conditionKeys.length;

    let missingFields = 0;

    if (unspecConditions > 0) {
      errors["conditions"].forEach(({ items = [], ...keys }: any) => {
        if (items.length === 0) {
          missingFields += Object.keys(keys).length;
        } else {
          items.forEach((item: any) => {
            missingFields += Object.keys(item).length;
          });
        }
      });
    }

    return (
      <Box
        position="fixed"
        style={{ top: 74, left: 254, width: "calc(100vw - 302px)" }}
        m={4}
        zIndex={10}
      >
        {conditionCount > 0 ? (
          <Banner severity="error" onClose={onClose}>
            {unspecConditions} condition
            {unspecConditions !== 1 ? "s have" : " has"} {missingFields}{" "}
            required field{missingFields !== 1 ? "s" : ""} that must be filled
            in order to specify.
          </Banner>
        ) : (
          <Banner severity="error" onClose={onClose}>
            There are no conditions to specify.
          </Banner>
        )}
      </Box>
    );
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        dispatch(resetSpecifyCondition());
        onClose();
      }}
    >
      <form onSubmit={handleSubmit(onSpecify)}>
        <Typography variant="h2">Specify Conditions</Typography>
        {bypassCommit && conditionCount > 0 && (
          <Box mt={4} mb={2}>
            <Typography sx={{ fontStyle: "italic" }}>
              Note: Specifying conditions of this assembly type will put its
              cells into the 'Committed' state.
            </Typography>
          </Box>
        )}
        <Box mt={8} mb={2}>
          {conditionCount > 0
            ? labels
            : autoCommitStatus === "loading"
            ? "Staging and Committing cell(s)..."
            : "There are no conditions available to specify."}
          {specifyErrors && (
            <>
              <Box display="flex" mt={4} mb={2}>
                <ExclamationCircleOutlined
                  color="error"
                  style={{ marginRight: 4 }}
                />
                <span style={{ color: colors.accent.red, marginRight: 4 }}>
                  Specification failed:{" "}
                </span>
              </Box>
              <Box>{specifyErrors}</Box>
            </>
          )}
        </Box>
        <Box mt={8} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            type="submit"
            disabled={
              Object.values(state).every((val) => !val) ||
              isLoading ||
              conditionCount === 0 ||
              !!specifyErrors
            }
            endIcon={
              isLoading ? <CircularProgress color="inherit" size={16} /> : null
            }
          >
            {bypassCommit ? "Specify & Commit" : "Specify"}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default SpecifyModal;
