// Show test stand flow if the cell_type.reserve_test_stands specifies it,
// see table definition.
export const showTestStandOptions = ({
  reserve_test_stands,
  override_test_stands,
  module_id,
}: {
  reserve_test_stands?: boolean | null;
  override_test_stands?: boolean | null;
  module_id?: number | null;
}) => {
  if (!reserve_test_stands || override_test_stands || !!module_id) return false;
  return true;
};
