import React, { useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavigationMenuHorizontalIcon from "../../icons/NavigationMenuHorizontal";
import { channelInfraStatusToString } from "../../utils/labels";
import { EXPLANATIONS_OF_SOME_INFRA_STATUSES } from "./ChannelTableCell";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { AuthState } from "../../store/auth/slice";

const INFRA_STATUSES_AVAILABLE_TO_ALL: ChannelInfraStatus[] = [
  "O",
  "C",
  "H",
  "W",
  "T",
  "R",
  "2",
  "V",
  "G",
  "F",
  "B",
  "P",
  "N",
  "3",
];
const INFRA_STATUSES_AVAILABLE_TO_MANAGERS: ChannelInfraStatus[] = [
  ...INFRA_STATUSES_AVAILABLE_TO_ALL,
  "M", // Decommissioned
];

type Props = {
  selected: string[];
  onChange: (infra_status: ChannelInfraStatus) => void;
};

const InfraStatusMenu = ({ selected, onChange }: Props) => {
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);
  const infraStatusOptions: ChannelInfraStatus[] = useMemo(() => {
    if (user?.role === "O") {
      return INFRA_STATUSES_AVAILABLE_TO_MANAGERS;
    }
    return INFRA_STATUSES_AVAILABLE_TO_ALL;
  }, [user]);
  const [buttonEl, setButtonEl] = useState<
    null | HTMLButtonElement | HTMLAnchorElement
  >(null);
  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.stopPropagation();
    setButtonEl(event.currentTarget);
  };
  const handleButtonClose = (
    event: React.MouseEvent<
      HTMLLIElement | HTMLButtonElement | HTMLAnchorElement
    >
  ) => {
    event.stopPropagation();
    setButtonEl(null);
  };

  const renderInfraStatusOption = (
    infra_status: ChannelInfraStatus,
    index: number | null
  ) => {
    return (
      <MenuItem
        key={index}
        onClick={(e) => {
          handleButtonClose(e);
          onChange(infra_status);
        }}
      >
        {channelInfraStatusToString(infra_status)}
      </MenuItem>
    );
  };

  return (
    <>
      <IconButton
        size="small"
        disabled={selected.length === 0}
        onClick={handleButtonClick}
      >
        <NavigationMenuHorizontalIcon style={{ width: 20, height: 20 }} />
      </IconButton>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {infraStatusOptions.map((infra_status, index) => {
          const infraStatusBlurb =
            EXPLANATIONS_OF_SOME_INFRA_STATUSES[infra_status];
          if (!!infraStatusBlurb) {
            return (
              <Tooltip
                arrow
                placement="left"
                title={EXPLANATIONS_OF_SOME_INFRA_STATUSES[infra_status]!}
                key={index}
              >
                {renderInfraStatusOption(infra_status, null)}
              </Tooltip>
            );
          }
          return renderInfraStatusOption(infra_status, index);
        })}
      </Menu>
    </>
  );
};

export default InfraStatusMenu;
