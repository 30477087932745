import {
  expStatusToString,
  cellStatusToString,
  channelStatusToString,
  channelInfraStatusToString,
  CHARACTERIZATION_SAMPLE_TYPES,
  TEARDOWN_FLAG_LABELS,
  DISCARD,
} from "./labels";

export const EXPERIMENT_FILTERS: FilterOptions<ExperimentFilters> = {
  exp_id: {
    type: "options",
    options: [],
  },
  owner: {
    type: "options_user",
  },
  project: {
    type: "options_project",
  },
  cell_count: {
    type: "number",
  },
  last_commit: {
    type: "date",
  },
  status: {
    type: "options",
    options: [
      {
        id: "I" as ExperimentStatus,
        label: expStatusToString("I"),
      },
      {
        id: "P" as ExperimentStatus,
        label: expStatusToString("P"),
      },
      {
        id: "U" as ExperimentStatus,
        label: expStatusToString("U"),
      },
      {
        id: "C" as ExperimentStatus,
        label: expStatusToString("C"),
      },
    ],
  },
  description: {
    type: "text",
  },
};

export const CELL_FILTERS: FilterOptions<CellFilters> = {
  condition__specified: {
    type: "date",
  },
  committed: {
    type: "date",
  },
  test__created_time: {
    type: "date",
  },
  cell_id: {
    type: "options",
    options: [],
  },
  experiment__exp_id: {
    type: "options",
    options: [],
  },
  experiment__project: {
    type: "options_project",
  },
  experiment__owner: {
    type: "options_user",
  },
  engineer_flag: {
    type: "options_componentflag",
  },
  deviations_from_sop: {
    type: "options_componentflag",
  },
  status: {
    type: "options",
    options: [
      {
        id: "I" as CellStatus,
        label: cellStatusToString("I"),
      },
      {
        id: "S" as CellStatus,
        label: cellStatusToString("S"),
      },
      {
        id: "T" as CellStatus,
        label: cellStatusToString("T"),
      },
      {
        id: "C" as CellStatus,
        label: cellStatusToString("C"),
      },
      {
        id: "A" as CellStatus,
        label: cellStatusToString("A"),
      },
      {
        id: "M" as CellStatus,
        label: cellStatusToString("M"),
      },
      {
        id: "D" as CellStatus,
        label: cellStatusToString("D"),
      },
      {
        id: "E" as CellStatus,
        label: cellStatusToString("E"),
      },
      {
        id: "L" as CellStatus,
        label: cellStatusToString("L"),
      },
      {
        id: "P" as CellStatus,
        label: cellStatusToString("P"),
      },
      {
        id: "N" as CellStatus,
        label: cellStatusToString("N"),
      },
      {
        id: "F" as CellStatus,
        label: cellStatusToString("F"),
      },
      {
        id: "O" as CellStatus,
        label: cellStatusToString("O"),
      },
      {
        id: "U" as CellStatus,
        label: cellStatusToString("U"),
      },
    ],
  },
  priority: {
    type: "options",
    options: [
      { id: "1", label: "1" },
      { id: "2", label: "2" },
      { id: "3", label: "3" },
      { id: "4", label: "4" },
      { id: "5", label: "5" },
    ],
  },
  channel__fullname: {
    type: "options",
    options: [],
  },
  position__shelf: {
    type: "options",
    options: [],
  },
  incubator__name: {
    type: "options",
    options: [],
  },
  tester__name: {
    type: "options",
    options: [],
  },
  reserved_channel__fullname: {
    type: "options",
    options: [],
  },
  condition__group_number: {
    type: "text",
  },
  condition__pool: {
    type: "options",
    options: [],
  },
  condition__cell_type: {
    type: "options",
    options: [],
  },
  condition__cell_assembly: {
    type: "options",
    options: [],
  },
  condition__build_config: {
    type: "options",
    options: [],
  },
  condition__build_phase: {
    type: "options",
    options: [],
  },
  full__build_test_location: {
    type: "options",
    options: [],
  },
  condition__name: {
    type: "text",
  },
  full__serial_number: {
    type: "options",
    options: [],
  },
  condition__plan_test_start_date: {
    type: "date",
  },
  gde__icm_pcm: {
    type: "options_icmpcm",
  },
  oee__icm_pcm: {
    type: "options_icmpcm",
  },
  anode__part_number: {
    type: "options_icmpcm",
  },
  anode__icm_pcm: {
    type: "options_icmpcm",
  },
  anode__additive_icm_pcm: {
    type: "options_icmpcm",
  },
  anode__assembly_type: {
    type: "options",
    options: [],
  },
  anode__qc_targets: {
    type: "options",
    options: [],
  },
  electrolyte__icm_pcm: {
    type: "options_icmpcm",
  },
  on_test__cycling_protocol: {
    type: "options_cyclingprotocol",
  },
  thermocouples_specified: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Specified",
      },
      {
        id: "0",
        label: "Not specified",
      },
    ],
  },
  thermocouples_attached: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Attached",
      },
      {
        id: "0",
        label: "Not attached",
      },
    ],
  },
  on_test__temperature_setpoint: {
    type: "text",
  },
  on_test__air_flow_rate_setpoint: {
    type: "text",
  },
  on_test__h2_sweep_gas_flow_rate: {
    type: "number",
  },
  test_meta__h2_sweep_gas_flow_rate: {
    type: "number",
  },
  on_test__eis_testing_beginning_of_life: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Specified",
      },
      {
        id: "0",
        label: "Not specified",
      },
    ],
  },
  anode__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  electrolyte__mix_task__completed: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Completed",
      },
      {
        id: "0",
        label: "Not completed",
      },
    ],
  },
  electrolyte__qa_task__completed: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Completed",
      },
      {
        id: "0",
        label: "Not completed",
      },
    ],
  },
  separator__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  gde__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  gde__gde_ids: {
    type: "options",
    options: [],
  },
  hca__hca_ids: {
    type: "options",
    options: [],
  },
  oee__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  oee__oee_ids: {
    type: "options",
    options: [],
  },
  counter__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  ref_anode__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  ref_gde__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  ref_oee__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  full__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  electrolyte__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  fill__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  on_test__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  on_test__adapter_cable_type: {
    type: "options",
    options: [],
  },
  ready_off__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  off_test__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  off_test__eis_testing_end_of_life: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Specified",
      },
      {
        id: "0",
        label: "Not specified",
      },
    ],
  },
  test_meta__adapter_cable_type: {
    type: "options",
    options: [],
  },
  teardown__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
  teardown__flags: {
    type: "options",
    options: TEARDOWN_FLAG_LABELS,
  },
  teardown__type: {
    type: "options",
    options: [
      {
        id: "Co-Engineering",
        label: "Co-Engineering",
      },
      {
        id: DISCARD,
        label: DISCARD,
      },
      {
        id: "Engineering",
        label: "Engineering",
      },
      {
        id: "Full",
        label: "Full",
      },
      {
        id: "Light",
        label: "Light",
      },
      {
        id: "Standard",
        label: "Standard",
      },
      {
        id: "AnodeDurability",
        label: "Anode Durability",
      },
    ],
  },
};

export const CHANNEL_FILTERS: FilterOptions<ChannelFilters> = {
  position__name: {
    type: "options",
    options: [],
  },
  position__shelf: {
    type: "options",
    options: [],
  },
  channel__fullname: {
    type: "options",
    options: [],
  },
  channel__status: {
    type: "options",
    options: [
      {
        id: "O" as ChannelStatus,
        label: channelStatusToString("O"),
      },
      {
        id: "I" as ChannelStatus,
        label: channelStatusToString("I"),
      },
    ],
  },
  channel__infra_status: {
    type: "options",
    options: [
      {
        id: "O" as ChannelInfraStatus,
        label: channelInfraStatusToString("O"),
      },
      {
        id: "C" as ChannelInfraStatus,
        label: channelInfraStatusToString("C"),
      },
      {
        id: "H" as ChannelInfraStatus,
        label: channelInfraStatusToString("H"),
      },
      {
        id: "M" as ChannelInfraStatus,
        label: channelInfraStatusToString("M"),
      },
      {
        id: "W" as ChannelInfraStatus,
        label: channelInfraStatusToString("W"),
      },
      {
        id: "T" as ChannelInfraStatus,
        label: channelInfraStatusToString("T"),
      },
      {
        id: "R" as ChannelInfraStatus,
        label: channelInfraStatusToString("R"),
      },
      {
        id: "2" as ChannelInfraStatus,
        label: channelInfraStatusToString("2"),
      },
      {
        id: "V" as ChannelInfraStatus,
        label: channelInfraStatusToString("V"),
      },
      {
        id: "G" as ChannelInfraStatus,
        label: channelInfraStatusToString("G"),
      },
      {
        id: "F" as ChannelInfraStatus,
        label: channelInfraStatusToString("F"),
      },
      {
        id: "B" as ChannelInfraStatus,
        label: channelInfraStatusToString("B"),
      },
      {
        id: "P" as ChannelInfraStatus,
        label: channelInfraStatusToString("P"),
      },
      {
        id: "N" as ChannelInfraStatus,
        label: channelInfraStatusToString("N"),
      },
      {
        id: "3" as ChannelInfraStatus,
        label: channelInfraStatusToString("3"),
      },
      // Deprecated:
      {
        id: "I" as ChannelInfraStatus,
        label: channelInfraStatusToString("I"),
      },
      {
        id: "U" as ChannelInfraStatus,
        label: channelInfraStatusToString("U"),
      },
      {
        id: "A" as ChannelInfraStatus,
        label: channelInfraStatusToString("A"),
      },
      {
        id: "E" as ChannelInfraStatus,
        label: channelInfraStatusToString("E"),
      },
      {
        id: "D" as ChannelInfraStatus,
        label: channelInfraStatusToString("D"),
      },
      {
        id: "L" as ChannelInfraStatus,
        label: channelInfraStatusToString("L"),
      },
      {
        id: "X" as ChannelInfraStatus,
        label: channelInfraStatusToString("X"),
      },
      {
        id: "S" as ChannelInfraStatus,
        label: channelInfraStatusToString("S"),
      },
    ],
  },
  tester__name: {
    type: "options",
    options: [],
  },
  test_stand__name: {
    type: "options",
    options: [],
  },
  channel__number: {
    type: "options",
    options: [],
  },
  incubator__name: {
    type: "options",
    options: [],
  },
  incubator__bay: {
    type: "options",
    options: [],
  },
  full__cell_component__serial_number: {
    type: "options",
    options: [],
  },
  channel__pool: {
    type: "options",
    options: [],
  },
  channel__preferred_test_vehicle: {
    type: "options",
    options: [],
  },
  channel__temperature: {
    type: "number",
  },
  channel__airlines: {
    type: "number",
  },
  channel__water: {
    type: "number",
  },
  channel__aux_channels: {
    type: "number",
  },
  channel__tc_channels: {
    type: "number",
  },
  channel__tester_brand: {
    type: "text",
  },
  channel__cathode_switching: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__co2_scrubbing: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__humidity_control: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__hydrogen_monitoring: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__aux_voltage_monitoring: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__eis_functionality: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__hazelnut_installed: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__subscale_h2_interlock_installed: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__thermal_uniformity_fans_installed: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
  channel__low_current_range: {
    type: "number",
  },
  channel__high_current_range: {
    type: "number",
  },
  channel__min_voltage: {
    type: "number",
  },
  channel__max_voltage: {
    type: "number",
  },
  experiment__exp_id: {
    type: "options",
    options: [],
  },
  experiment__project: {
    type: "options_project",
  },
  experiment__owner: {
    type: "options_user",
  },
  res__exp_id: {
    type: "options",
    options: [],
  },
  res__owner: {
    type: "options_user",
  },
  cell__cell_id: {
    type: "options",
    options: [],
  },
  reserved_cell__cell_id: {
    type: "options",
    options: [],
  },
  reserved_cell__cell_id__null: { type: "number" },
  cell_condition__cell_type: {
    type: "options",
    options: [],
  },
  cell_condition__cell_assembly: {
    type: "options",
    options: [],
  },
  test_meta__ready_off__completed_at__null: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Not completed",
      },
      {
        id: "0",
        label: "Completed",
      },
    ],
  },
};

export const TEST_STAND_FILTERS: FilterOptions<TestStandFilters> = {
  name: {
    type: "options",
    options: [],
  },
  h2_sensor_type: {
    type: "options",
    options: [],
  },
  anode_current_sensors: {
    type: "options",
    options: [
      {
        id: "1",
        label: "Yes",
      },
      {
        id: "0",
        label: "No",
      },
    ],
  },
};

export const SAMPLE_FILTERS: FilterOptions<SampleFilters> = {
  sample_field__cell_id: {
    type: "options",
    options: [],
  },
  sample_field__exp_id: {
    type: "options",
    options: [],
  },
  sample_field__sample_owner: {
    type: "options",
    options: [],
  },
  sample_field__sample_type: {
    type: "options",
    options: CHARACTERIZATION_SAMPLE_TYPES,
  },
  // specimen_field__technique_requested: {
  //   type: "options",
  //   options: TECHNIQUE_TYPES,
  // },
  sample_id: {
    type: "options",
    options: [],
  },
};
