import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import client from "../../api";
import Button from "../../components/Button";
import ArrowBackIcon from "../../icons/ArrowBack";
import TimesIcon from "../../icons/Times";
import { useNavigate } from "react-router-dom";
import { ExperimentField, Description, Header, Error } from "./ExperimentField";
import { Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExperimentLoading from "./ExperimentLoading";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { listProjects } from "./projectSlice";
import { RootState } from "../../store";

import { ProjectListState } from "./projectSlice";
import Toast from "../../components/Toast";

const ExperimentCreate = () => {
  const navigate = useNavigate();

  const [purpose, setPurpose] = useState("");
  const [notes, setNotes] = useState("");
  const [project, setProject] = useState<String | null>(null);
  const [fscProject, setFscProject] = useState<String>("");
  const [slide, setSlide] = useState(false);
  const [error, setError] = useState("");
  const [projectAddError, setProjectAddError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    projects,
    status,
    error: projectError,
  } = useSelector<RootState, ProjectListState>(
    ({ projectList }) => projectList
  );
  useEffect(() => {
    dispatch(listProjects());
  }, [dispatch]);

  let errorToast;
  if (status === "failed") {
    errorToast = (
      <Toast open severity="error" onClose={() => dispatch(listProjects())}>
        {projectError}
      </Toast>
    );
  }

  const handleCreateExp = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.post(`meta/experiments/create`, {
        purpose,
        notes,
        slide,
        project,
      });
      setLoading(false);
      navigate(`/experiments/${response.experiment_id}`, { replace: true });
    } catch (error) {
      setLoading(false);
      setError(`${error}`);
    }
  }, [purpose, notes, navigate, slide, project]);

  const handleAddProject = useCallback(async () => {
    setProjectAddError("");
    try {
      const name = fscProject;
      await client.post(`meta/experiments/projects/create`, {
        name,
      });
      setFscProject("");
      dispatch(listProjects());
    } catch (error) {
      setProjectAddError(`Application error handling project creation: ${error}`);
    }
  }, [fscProject, dispatch]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (purpose === "") {
          navigate(-1);
        } else {
          handleClear();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [navigate, handleCreateExp, purpose, notes, slide, project]);

  const handleClear = () => {
    setPurpose("");
    setNotes("");
    setProject("");
    setFscProject("");
  };

  return (
    <div>
      {loading ? (
        <ExperimentLoading light fullscreen />
      ) : (
        <Box style={{ background: "#FFF", minHeight: "125vh" }} px={6} py={3}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton size="large" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton size="large" onClick={() => navigate(-1)}>
              <TimesIcon />
            </IconButton>
          </Box>

          <Box mt={8} mx="auto" display="flex" justifyContent="center">
            <div style={{ width: 440 }}>
              <Typography variant="h2">Create Experiment</Typography>

              <Box mt={4} mb={6}>
                <Divider />
              </Box>

              <ExperimentField
                title="Title"
                description="Please provide a short descriptive experiment title."
                value={purpose}
                updateText={setPurpose}
                maxLen={511}
                example="Probing the Effect of Towhee and Undersized Zonetail on Cell Failure Modes in HCAs"
              />

              <ExperimentField
                title="Notes (optional)"
                description="Anything that you think may be helpful down the road to facilitate searching across data and experiments."
                value={notes}
                updateText={setNotes}
                maxLen={900}
              />

              <Header color="textSecondary">Project</Header>
              <Description color="textSecondary">
                Which project/sprint is this work most closely associated with?
                Select "Other" if you aren't sure.
              </Description>
              <Autocomplete
                disablePortal
                id="project-box"
                options={projects}
                getOptionLabel={(option) => option.name}
                ListboxProps={{ style: { maxHeight: 150 } }}
                sx={{ width: 1, color: "textSecondary" }}
                onChange={(e, newProject) => {
                  if (newProject) {
                    setProject(newProject.name);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    variant="outlined"
                    size="small"
                    color="secondary"
                    placeholder="Start Typing..."
                    label=""
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box marginLeft={"15px"} px={4} py={2}>
                      <Typography color="textSecondary">
                        {option.name}
                      </Typography>
                    </Box>
                  </li>
                )}
              />

              <Box mt={10} mb={10}></Box>
              <Description color="textSecondary">
                Add FSC Project
                {projectAddError ? <Box><Divider /><Error>{projectAddError}</Error></Box> : null}
              </Description>
              <TextField
                id="fsc-project"
                label="Name for a new FSC project. Use jog gsheet for SSC."
                value={fscProject}
                fullWidth
                onChange={(event) => {
                    setFscProject(event.target.value);
                }}
              />
              <Button
                  id="fsc-project-add"
                  disabled={!fscProject}
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={() => handleAddProject()}
              >
                Add
              </Button>

              <Box mt={10} mb={10}></Box>
              <Header color="textSecondary">Slides Template</Header>
              <Description color="textSecondary">
                Would you like to have a template data presentation deck
                automatically copied to the EXP folder?
              </Description>

              <FormControl fullWidth>
                <FormControlLabel
                  style={{ color: "textSecondary" }}
                  control={<Checkbox onChange={(e) => setSlide(!slide)} />}
                  label={
                    <Typography color="textSecondary" fontSize="0.875rem">
                      Create Presentation Deck
                    </Typography>
                  }
                />
              </FormControl>

              <Box mt={6} mb={4}>
                <Divider />
                {error ? <Error>{error}</Error> : null}
              </Box>

              <Button
                color="cta"
                style={{ width: "100%" }}
                onClick={() => handleCreateExp()}
              >
                Create
              </Button>
            </div>
          </Box>
          {errorToast}
        </Box>
      )}
    </div>
  );
};

export default ExperimentCreate;
