import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Link,
  Table,
  TableBody,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import colors from "../../../../theme/colors";
import InfoBlock, { InnerBlockProps } from "../../../metadata/layout/InfoBlock";
import BaseTableRow from "../../../../components/forms/BaseTableRow";
import ThCell from "../../../../components/forms/ThCell";
import {
  ANODE_MATERIAL_OPTIONS,
  ELYTE_STATE_OPTIONS,
  ELYTE_STORAGE_OPTIONS,
  ALL_FORM_FACTOR_OPTIONS,
  GDE_MATERIAL_OPTIONS,
  getCharacterizationLabelForId,
  OEE_MATERIAL_OPTIONS,
  OEE_MESH_TYPE_OPTIONS,
  QUANTITY_TYPE_OPTIONS,
  SAMPLE_TYPE_SLUDGE_OPTIONS,
  SANDBLASTED_OPTIONS,
  SPECIMEN_PRIORITY_OPTIONS,
  TECHNIQUE_TYPES,
  SPECIMEN_REQUEST_TYPE_OPTIONS,
  ICP_SPECIMEN_REQUEST_TYPE_OPTIONS,
  cellIdToString,
  expIdToString,
  sampleIdToString,
  SAMPLE_LOCATION_OPTIONS,
  isoDateToLocalTimeString,
  ELYTE_SAMPLE_LOCATION_OPTIONS,
} from "../../../../utils/labels";
import InputCell from "../../../../components/forms/InputCell";
import TextareaCell from "../../../../components/forms/TextareaCell";
import Button from "../../../../components/Button";
import PlusCircle from "../../../../icons/PlusCircle";
import CheckboxCell from "../../../../components/forms/CheckboxCell";
import { useDispatch, useSelector } from "react-redux";
import {
  CELL_ID_LOOKUP_ID,
  CharacterizationState,
  EXP_ID_LOOKUP_ID,
  getSampleData,
  resetGetSampleData,
  resetSaveSpecimens,
  SAMPLE_OWNER_LOOKUP_ID,
  saveSpecimens,
  createCharacterizationRequest,
  TECHNIQUE_REQUESTED_LOOKUP_ID,
  STATUS_FIELD_ID,
  ICM_PCM_LOOKUP_ID,
  SAMPLE_TYPE_LOOKUP_ID,
  QUANTITY_UNIT_LOOKUP_ID,
  ELYTE_STATE_LOOKUP_ID,
  HOT_POCKET_LOOKUP_ID,
  DATE_SAMPLE_HARVESTED_LOOKUP_ID,
  LOCATION_LOOKUP_ID,
  ELYTE_LOOKUP_ID,
} from "../../slice";
import { isEmpty, isNull } from "lodash";
import { RootState } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { addZeroTimeZoneIfAmbiguous } from "../../../../utils/timeHelpers";

const SHOW_SPECIMEN_INFO = false;

const sectionHeaderStyles = {
  backgroundColor: colors.striping,
  borderBottom: `1px solid ${colors.rules}`,
};

const sampleHeaderStyles = {
  ...sectionHeaderStyles,
  position: "sticky",
  top: 66,
  zIndex: 1,
} as React.CSSProperties;

const notNull = (
  itemInArray: InnerBlockProps | null
): itemInArray is InnerBlockProps => {
  return !isNull(itemInArray);
};

const SampleSection = ({
  sample,
  sampleIndex,
  specimenTemplate,
  allSampleIds,
}: {
  sample: CharacterizationSample;
  sampleIndex: number;
  specimenTemplate: Specimen;
  allSampleIds: number[];
}) => {
  const {
    status: {
      saveSpecimens: specimenSaveStatus,
      getSampleData: getSampleStatus,
      createCharacterizationRequest: c14RequestStatus,
    },
    updatedSampleIds,
  } = useSelector<RootState, CharacterizationState>(
    ({ characterization }) => characterization
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const thisSampleUpdated = updatedSampleIds.includes(`${sample.sample_id!}`);
  const sampleType = sample.fields.find(
    ({ field_lookup_id }) => field_lookup_id === SAMPLE_TYPE_LOOKUP_ID
  )!.value;
  const sampleFieldsToDisplay = sample.fields.filter(
    ({ conditional_for_sample_types }) =>
      !conditional_for_sample_types ||
      conditional_for_sample_types.includes(sampleType as string)
  );

  const unsavedChanges = () => {
    // Returns boolean of whether or not the form has unsaved changes
    return (
      dirtyFields.specimens &&
      dirtyFields.specimens.some((_specimen, _specimenIndex) =>
        _specimen?.fields?.some(({ value }) => value)
      ) &&
      specimens.every((_specimen, _specimenIndex) => {
        const techniqueRequestedIndex = _specimen.fields.findIndex(
          (_specimen_field) =>
            _specimen_field.field_lookup_id === TECHNIQUE_REQUESTED_LOOKUP_ID
        );
        return getValues(
          `specimens.${_specimenIndex}.fields.${techniqueRequestedIndex}.value`
        );
      })
    );
  };

  // Form management
  const { control, handleSubmit, formState, getValues, setValue, reset } =
    useForm<{
      specimens: Specimen[];
    }>({
      defaultValues: {
        specimens:
          sample.specimens.length > 0 ? sample.specimens : [specimenTemplate],
      },
    });
  const { fields: specimens, append } = useFieldArray({
    control,
    name: "specimens",
  });
  useWatch({
    control,
    name: "specimens",
  });
  const { dirtyFields } = formState;

  useEffect(() => {
    if (
      thisSampleUpdated &&
      (specimenSaveStatus === "succeeded" || c14RequestStatus === "succeeded")
    ) {
      dispatch(
        getSampleData({ sampleIds: `${sample.sample_id}`, partialUpdate: true })
      );
    }
  }, [
    specimenSaveStatus,
    c14RequestStatus,
    thisSampleUpdated,
    sample.sample_id,
    dispatch,
  ]);

  useEffect(() => {
    if (thisSampleUpdated && getSampleStatus === "succeeded") {
      reset({ specimens: sample.specimens });
      dispatch(resetGetSampleData());
    }
  }, [thisSampleUpdated, getSampleStatus, dispatch, sample.specimens, reset]);

  const addSampleIdCreationTimeObj = (
    sampleFieldsAsInfoBlockFields: InnerBlockProps[]
  ) => {
    // Put 'Sample ID created at' InfoBlock object just before 'Date harvested' InfoBlock object
    const sampleIdCreationTimeObj = {
      title: "Date Sample ID created",
      contents: [
        isoDateToLocalTimeString(
          addZeroTimeZoneIfAmbiguous(sample.created_time) as string | null
        ) || "-",
      ],
    };
    const dateHarvestedIndex = sampleFieldsToDisplay.findIndex(
      (sampleField_) =>
        sampleField_.field_lookup_id === DATE_SAMPLE_HARVESTED_LOOKUP_ID
    );
    // If 'Date harvested' field isn't found, put it on second row of sample fields in details page
    const insertAtIndex = dateHarvestedIndex >= 0 ? dateHarvestedIndex : 3;
    sampleFieldsAsInfoBlockFields.splice(
      insertAtIndex,
      0,
      sampleIdCreationTimeObj
    );
    return sampleFieldsAsInfoBlockFields;
  };

  return (
    <div
      key={sample.sample_id}
      style={{
        width: "calc(100vw - 256px)",
        paddingLeft: 274,
      }}
    >
      <Box>
        <Box
          px={4}
          py={2}
          pr={4}
          style={
            sampleIndex === 0
              ? sampleHeaderStyles
              : {
                  ...sampleHeaderStyles,
                  borderTop: `1px solid ${colors.rules}`,
                }
          }
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "66%",
            }}
          >
            <Box style={{ display: "flex" }}>
              <Typography variant="h3">
                {sampleIdToString(sample.sample_id!)}
              </Typography>
            </Box>
            <Box justifySelf="flex-end">
              <Button
                color="secondary"
                size="small"
                style={{ display: "block", marginLeft: 12 }}
                onClick={() =>
                  navigate(
                    `/characterization/edit-samples?sample_id=${sample.sample_id}`,
                    {
                      state: {
                        from: {
                          pathname: `/characterization/samples?sample_id=${allSampleIds.join(
                            ","
                          )}`,
                        },
                      },
                    }
                  )
                }
              >
                Edit sample
              </Button>

              {SHOW_SPECIMEN_INFO && (
                <Button
                  color="primary"
                  size="small"
                  disabled={!unsavedChanges()}
                  type="submit"
                  onClick={handleSubmit((formData) => {
                    dispatch(resetSaveSpecimens());
                    dispatch(
                      saveSpecimens({
                        sampleId: sample.sample_id!,
                        specimens: formData.specimens,
                      })
                    );
                  })}
                >
                  {`Save ${sampleIdToString(
                    sample.sample_id!
                  )} specimen(s)/request(s)`}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box mx={4}>
          <InfoBlock
            items={addSampleIdCreationTimeObj(
              sampleFieldsToDisplay
                .map(({ field_label, value, field_lookup_id }) => {
                  let val;
                  switch (field_lookup_id) {
                    case QUANTITY_UNIT_LOOKUP_ID:
                      return null;
                    case "quantity":
                      const quantityUnitVal =
                        sampleFieldsToDisplay.find(
                          ({ field_lookup_id }) =>
                            field_lookup_id === QUANTITY_UNIT_LOOKUP_ID
                        )?.value || "";
                      val = value
                        ? `${value} ${
                            quantityUnitVal &&
                            QUANTITY_TYPE_OPTIONS.find(
                              ({ id }) => id === quantityUnitVal
                            )!.label
                          }`
                        : "-";
                      break;
                    case "parent_sample_id":
                      val = value ? sampleIdToString(value as string) : "-";
                      break;
                    case "sample_type":
                      val = value
                        ? getCharacterizationLabelForId(value as string) ||
                          value
                        : "-";
                      break;
                    case CELL_ID_LOOKUP_ID:
                      val = value ? cellIdToString(value as string) : "-";
                      break;
                    case ELYTE_STATE_LOOKUP_ID:
                    case "type_of_material_anode":
                    case "type_of_material_gde":
                    case "type_of_material_oee":
                    case "elyte_storage":
                    case "form_factor":
                    case LOCATION_LOOKUP_ID:
                    case "oee_mesh_type":
                    case "sample_prep_sludge":
                    case "sandblasted":
                      const lookupId = field_lookup_id as
                        | "elyte_state"
                        | "form_factor"
                        | "location"
                        | "oee_mesh_type"
                        | "type_of_material_anode"
                        | "type_of_material_gde"
                        | "type_of_material_oee"
                        | "elyte_storage"
                        | "sample_prep_sludge"
                        | "sandblasted";
                      const optionsByField = {
                        elyte_state: ELYTE_STATE_OPTIONS,
                        form_factor: ALL_FORM_FACTOR_OPTIONS,
                        type_of_material_anode: ANODE_MATERIAL_OPTIONS,
                        type_of_material_gde: GDE_MATERIAL_OPTIONS,
                        type_of_material_oee: OEE_MATERIAL_OPTIONS,
                        elyte_storage: ELYTE_STORAGE_OPTIONS,
                        location: [
                          ...SAMPLE_LOCATION_OPTIONS,
                          ...ELYTE_SAMPLE_LOCATION_OPTIONS,
                        ],
                        oee_mesh_type: OEE_MESH_TYPE_OPTIONS,
                        sample_prep_sludge: SAMPLE_TYPE_SLUDGE_OPTIONS,
                        sandblasted: SANDBLASTED_OPTIONS,
                      };
                      val = !isEmpty(value)
                        ? optionsByField[lookupId].find(
                            ({ id }) => id === value
                          )!.label
                        : "-";
                      break;
                    case "location_other_description":
                      const locationVal = sampleFieldsToDisplay.find(
                        ({ field_lookup_id }) =>
                          field_lookup_id === LOCATION_LOOKUP_ID
                      )?.value;
                      if (locationVal === "other") {
                        val = value || "-";
                      } else {
                        return null;
                      }
                      break;
                    case EXP_ID_LOOKUP_ID:
                      val = value ? expIdToString(value as string) : "-";
                      break;
                    case HOT_POCKET_LOOKUP_ID:
                    case "precipitates":
                      val = value ? "Yes" : "-";
                      break;
                    case "air_sensitive":
                      val = value ? "Yes" : "No";
                      break;
                    case DATE_SAMPLE_HARVESTED_LOOKUP_ID:
                      val =
                        isoDateToLocalTimeString(value as string | null) || "-";
                      break;
                    case SAMPLE_OWNER_LOOKUP_ID:
                      val = !isEmpty(sample.sample_owners)
                        ? sample.sample_owners
                            .map((user) => user.name)
                            .join(", ")
                        : "-";
                      break;
                    case "anode_pcm":
                    case "elyte_pcm":
                    case "hot_pocket_pcm":
                    case ICM_PCM_LOOKUP_ID:
                    case "arena_part_no":
                      const materialObj = value as Material;
                      val = materialObj ? materialObj.material_id : "-";
                      break;
                    default:
                      val = value || "-";
                  }
                  return {
                    title: field_label,
                    contents: [val],
                  };
                })
                .filter(notNull) as InnerBlockProps[]
            )}
          />
        </Box>

        {SHOW_SPECIMEN_INFO &&
          specimens.map((specimen, specimenIndex) => {
            const techniqueRequestedIndex = specimen.fields.findIndex(
              (_specimen_field) =>
                _specimen_field.field_lookup_id ===
                TECHNIQUE_REQUESTED_LOOKUP_ID
            );
            const techniqueRequestedVal = getValues(
              `specimens.${specimenIndex}.fields.${techniqueRequestedIndex}.value`
            );
            const sectionTitle = techniqueRequestedVal
              ? getCharacterizationLabelForId(
                  techniqueRequestedVal as string,
                  "techniques"
                )
              : "New characterization request";

            return (
              <form key={specimen.id}>
                <Box px={4} py={2} style={{ ...sectionHeaderStyles }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "66%",
                    }}
                  >
                    <Box pt={1}>
                      <Typography variant="h3">{sectionTitle}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box my={4} mx={4}>
                  <Table size="small" style={{ width: "auto" }}>
                    <TableBody>
                      {specimen.fields.map(
                        (
                          {
                            field_label,
                            field_lookup_id,
                            field_id,
                            link,
                            value,
                            conditional_for_techniques,
                            required,
                          },
                          specimenFieldIndex
                        ) => {
                          if (
                            !isEmpty(conditional_for_techniques) &&
                            !conditional_for_techniques?.includes(
                              techniqueRequestedVal as string
                            )
                          ) {
                            return null;
                          }
                          let formCell;
                          switch (field_lookup_id) {
                            case TECHNIQUE_REQUESTED_LOOKUP_ID:
                              const loadedTechnique = getValues(
                                `specimens.${specimenIndex}.fields.${techniqueRequestedIndex}.value`
                              );
                              formCell = (
                                <Controller
                                  name={`specimens.${specimenIndex}.fields.${techniqueRequestedIndex}.value`}
                                  control={control}
                                  rules={{ required: !!required }}
                                  render={({
                                    field: { onChange, onBlur, value },
                                    fieldState: { invalid },
                                  }) => (
                                    <TableCell padding="none" width={326}>
                                      <FormControl fullWidth>
                                        <Autocomplete
                                          options={TECHNIQUE_TYPES}
                                          onBlur={onBlur}
                                          onChange={(_event, newVal) => {
                                            onChange(newVal?.id || newVal);
                                          }}
                                          value={
                                            loadedTechnique
                                              ? TECHNIQUE_TYPES.find(
                                                  (techniqueType) =>
                                                    techniqueType.id ===
                                                    loadedTechnique
                                                )
                                              : null
                                          }
                                          renderInput={(params) => {
                                            const description =
                                              value &&
                                              TECHNIQUE_TYPES.find(
                                                (technique) =>
                                                  technique.id === value
                                              )?.description;
                                            const renderTextField = () => (
                                              <TextField
                                                {...params}
                                                color="secondary"
                                                InputProps={{
                                                  ...params.InputProps,
                                                  error: invalid,
                                                }}
                                              />
                                            );
                                            return (
                                              <>
                                                {description ? (
                                                  <Tooltip
                                                    arrow
                                                    title={description}
                                                    placement="right"
                                                  >
                                                    {renderTextField()}
                                                  </Tooltip>
                                                ) : (
                                                  renderTextField()
                                                )}
                                              </>
                                            );
                                          }}
                                          renderOption={(
                                            props,
                                            { description, label }
                                          ) => (
                                            <li {...props}>
                                              <Box px={3} py={2} width={208}>
                                                <Typography
                                                  color="textPrimary"
                                                  className="small"
                                                >
                                                  {label}
                                                </Typography>
                                                {description ? (
                                                  <Typography
                                                    color="textSecondary"
                                                    className="tiny"
                                                  >
                                                    {description}
                                                  </Typography>
                                                ) : null}
                                              </Box>
                                            </li>
                                          )}
                                        />
                                      </FormControl>
                                    </TableCell>
                                  )}
                                />
                              );
                              break;
                            case "air_sensitive":
                              let priorityFieldIndex: number | undefined;
                              if (field_lookup_id === "air_sensitive") {
                                priorityFieldIndex = specimen.fields.findIndex(
                                  (_specimen_field) =>
                                    _specimen_field.field_lookup_id ===
                                    "priority"
                                );
                              }
                              formCell = (
                                <Controller
                                  control={control}
                                  name={`specimens.${specimenIndex}.fields.${specimenFieldIndex}.value`}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      name,
                                      ref,
                                    },
                                    fieldState: { invalid },
                                  }) => (
                                    <CheckboxCell
                                      color="secondary"
                                      checked={!!value}
                                      onChange={(event) => {
                                        onChange(event.target.checked);

                                        if (
                                          priorityFieldIndex &&
                                          priorityFieldIndex !== -1 &&
                                          event.target.checked
                                        ) {
                                          const priorityVal = getValues(
                                            `specimens.${specimenIndex}.fields.${priorityFieldIndex}.value`
                                          );
                                          if (!priorityVal) {
                                            const p1 =
                                              SPECIMEN_PRIORITY_OPTIONS.find(
                                                ({ id }) => id === "p1"
                                              );
                                            p1 &&
                                              setValue(
                                                `specimens.${specimenIndex}.fields.${priorityFieldIndex}.value`,
                                                p1.id
                                              );
                                          }
                                        }
                                      }}
                                    />
                                  )}
                                />
                              );
                              break;
                            case "priority":
                            case "specimen_request_type":
                              const lookupId = field_lookup_id as
                                | "priority"
                                | "specimen_request_type";
                              const optionsByField = {
                                priority: SPECIMEN_PRIORITY_OPTIONS,
                                specimen_request_type:
                                  techniqueRequestedVal === "icp_oes"
                                    ? ICP_SPECIMEN_REQUEST_TYPE_OPTIONS
                                    : SPECIMEN_REQUEST_TYPE_OPTIONS,
                              };
                              const _options = optionsByField[lookupId];
                              formCell = (
                                <Controller
                                  name={`specimens.${specimenIndex}.fields.${specimenFieldIndex}.value`}
                                  control={control}
                                  rules={{ required: !!required }}
                                  render={({
                                    field: { onChange, onBlur, value },
                                    fieldState: { invalid },
                                  }) => (
                                    <TableCell padding="none" width={326}>
                                      <FormControl fullWidth>
                                        <Autocomplete
                                          options={_options}
                                          onBlur={onBlur}
                                          onChange={(_event, newVal) => {
                                            onChange(newVal?.id || newVal);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              color="secondary"
                                              InputProps={{
                                                ...params.InputProps,
                                                error: invalid,
                                              }}
                                            />
                                          )}
                                          renderOption={(props, { label }) => (
                                            <li {...props}>
                                              <Box px={4} py={2}>
                                                {label}
                                              </Box>
                                            </li>
                                          )}
                                          value={
                                            (value &&
                                              _options.find(
                                                ({ id }) => id === value
                                              )) ||
                                            (lookupId ===
                                              "specimen_request_type" &&
                                              techniqueRequestedVal ===
                                                "icp_oes" &&
                                              _options.find(({ id }) =>
                                                sampleType === ELYTE_LOOKUP_ID
                                                  ? id === "internal"
                                                  : id === "external_rj_lee"
                                              )) ||
                                            null
                                          }
                                        />
                                      </FormControl>
                                    </TableCell>
                                  )}
                                />
                              );
                              break;
                            case "specimen_location":
                              formCell = (
                                <Controller
                                  name={`specimens.${specimenIndex}.fields.${specimenFieldIndex}.value`}
                                  control={control}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      name,
                                      ref,
                                    },
                                    fieldState: { invalid },
                                  }) => (
                                    <TextareaCell
                                      ref={ref}
                                      name={name}
                                      value={value || ""}
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      error={invalid}
                                    />
                                  )}
                                />
                              );
                              break;
                            case STATUS_FIELD_ID:
                              formCell = (
                                <TableCell
                                  width={326}
                                  style={{ borderRight: "1px solid #E7E7E7" }}
                                >
                                  <FormControl fullWidth>
                                    {link ? (
                                      <Link
                                        href={link}
                                        target="_blank"
                                        rel="noreferrer"
                                        color="secondary"
                                      >
                                        {value || ""}
                                      </Link>
                                    ) : (
                                      <>{value || ""}</>
                                    )}
                                  </FormControl>
                                </TableCell>
                              );
                              break;
                            default:
                              formCell = (
                                <Controller
                                  key={`${field_lookup_id}-${field_id}`}
                                  name={`specimens.${specimenIndex}.fields.${specimenFieldIndex}.value`}
                                  control={control}
                                  defaultValue={value || ""}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      name,
                                      ref,
                                    },
                                    fieldState: { invalid },
                                  }) => (
                                    <InputCell
                                      ref={ref}
                                      name={name}
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      value={value || ""}
                                      error={invalid}
                                    />
                                  )}
                                />
                              );
                          }

                          return (
                            <BaseTableRow
                              key={`${specimenIndex}-${field_lookup_id}-${specimenFieldIndex}`}
                            >
                              <ThCell title={field_label} required={!!required}>
                                {field_label}
                              </ThCell>
                              {formCell}
                            </BaseTableRow>
                          );
                        }
                      )}

                      {["WIP", undefined, null].includes(
                        specimen.fields.find(
                          ({ field_lookup_id }) =>
                            field_lookup_id === STATUS_FIELD_ID
                        )?.value as string
                      ) && (
                        <BaseTableRow>
                          <ThCell />
                          <TableCell
                            style={{ borderRight: `1px solid ${colors.rules}` }}
                          >
                            <Box p={2}>
                              <Button
                                color="tertiary"
                                type="submit"
                                size="small"
                                onClick={handleSubmit((data) => {
                                  dispatch(
                                    createCharacterizationRequest({
                                      sample_id: sample.sample_id!,
                                      specimen_id: specimen.specimen_id!,
                                    })
                                  );
                                })}
                                disabled={
                                  sample.specimens.length !==
                                    specimens.length ||
                                  unsavedChanges() ||
                                  c14RequestStatus === "loading"
                                }
                              >
                                <b>Submit Characterization Request</b>
                              </Button>
                            </Box>
                          </TableCell>
                        </BaseTableRow>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </form>
            );
          })}
      </Box>

      {SHOW_SPECIMEN_INFO && (
        <Box py={4} pl={4}>
          <Button
            color="tertiary"
            type="button"
            style={{ display: "block" }}
            onClick={() => append(specimenTemplate)}
          >
            <div style={{ display: "flex" }}>
              <PlusCircle />
              <span style={{ marginLeft: 8 }}>
                <b>Add new characterization request/specimen</b>
              </span>
            </div>
          </Button>
        </Box>
      )}
    </div>
  );
};

export default SampleSection;
